import { defineStore } from 'pinia'
import { userService } from '../services/userService'
import lock from '../auth/auth0-lock'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        selectedCountry: localStorage.getItem('selectedCountry') || null,
        bziUser: null,
    }),

    getters: {
        isLoggedIn: (state) => !!state.bziUser,
    },

    actions: {
        async checkLoginStatus() {
            if (localStorage.getItem('access_token')) {
                await this.fetchUserProfile(localStorage.getItem('access_token'));
            } else {
                this.user = null
                this.bziUser = null
            }
        },

        async fetchUserProfile(accessToken) {
            return new Promise((resolve, reject) => {
                lock.getUserInfo(accessToken, async (error, profile) => {
                    if (error) {
                        console.error("Error getting profile", error);
                        reject(error);
                        return;
                    }
                    this.user = {
                        auth0Id: profile.sub,
                        email: profile.email,
                        name: profile.name,
                        headPic: profile.picture
                    }
                    try {
                        await this.fetchBziUser(this.user.auth0Id)
                    } catch (error) {
                        console.error("Error fetching BZI user", error);
                    }
                    resolve(profile);
                });
            });
        },

        async fetchBziUser(auth0Id) {
            try {
                const response = await userService.getUserByAuth0Id(auth0Id);
                if (!!response && response.auth0Id === auth0Id) {
                    this.bziUser = response;
                    this.user.userId = response.userId;
                    return this.bziUser;
                } else {
                    return this.createBziUser(auth0Id);
                }
            } catch (error) {
                console.error("Error fetching BZI user", error);
                // 如果用户不存在，可能需要创建新用户
                if (error.response && error.response.status === 404) {
                    return this.createBziUser(auth0Id);
                }
                throw error;
            }
        },

        async createBziUser(auth0Id) {
            try {
                const newUser = {
                    auth0Id: auth0Id,
                    email: this.user.email,
                    username: this.user.name,
                    headPic: this.user.headPic,
                };
                const response = await userService.createUser(newUser);
                this.bziUser = response;
                this.user.userId = response.userId;
                return this.bziUser;
            } catch (error) {
                console.error("Error creating BZI user", error);
                throw error;
            }
        },

        setSelectedCountry(countryName) {
            this.selectedCountry = countryName
            localStorage.setItem('selectedCountry', countryName)
        },

        getSelectedCountry() {
            return this.selectedCountry || localStorage.getItem('selectedCountry')
        },

        getUserName() {
            return this.bziUser?.name
        },

        getUserId() {
            return this.bziUser?.userId
        },

        logout() {
            lock.logout({
                redirectUrl: window.location.origin
            })
            localStorage.removeItem('id_token');
            localStorage.removeItem('access_token');
            this.user = null
            this.bziUser = null
        },

        showLock() {
            lock.show();
        },

        closeLock() {
            lock.hide();
        }
    },
})