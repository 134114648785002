export const ENDPOINTS = {
    USER: {
        GET: 'api/user/fetch',
        UPDATE: 'api/user',
        CREATE: 'api/user/create',
        GET_BY_MEMID: 'api/user/fetch/byAuth0Id'
    },
    RESTART_LIFE: 'api/life/restart',
    SEED: {
        TALENTS: 'api/seed/talents/random',
        ROLES: 'api/seed/roles/random'
    },
    STATS: {
        TOTAL: 'api/stats/total'
    },
    RANKING: {
        GET_WORLD: 'api/ranking/countries',
        GET_COUNTRY: 'api/ranking/country',
        GET_ALL_USERS: 'api/ranking/users',
        GET_USER: 'api/ranking/user'
    },
    COUNTDOWN: {
        ACTIVITY: 'api/countdown/activity/fetch',
    },
    REWARDS: {
        FETCH: 'api/rewards/fetch',
        CHECK: 'api/rewards/check'
    }
};

