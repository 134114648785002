<template>
    <div class="season-rewards-overlay" v-if="isVisible">
        <div class="season-rewards-modal" :class="{ 'loading-state': loading }">
            <h2>{{ isSeasonEnded ? 'Season Rewards' : 'Current Rankings' }}</h2>
            <div v-if="loading" class="loading">Loading...</div>
            <div v-else class="rewards-content">
                <div class="scrollable-content">
                    <div class="user-stats">
                        <h3 style="margin-top: 8px;">Your Stats</h3>
                        <div v-if="userStats" class="stats-display">
                            <div class="stat-item">
                                <span class="stat-label">Rank:</span>
                                <span class="stat-value">#{{ userStats.rank }}</span>
                            </div>
                            <div class="stat-item">
                                <span class="stat-label">Score:</span>
                                <span class="stat-value">{{ userStats.weightedScore.toFixed(1) }}</span>
                            </div>
                            <div class="stat-item">
                                <span class="stat-label">Play Count:</span>
                                <span class="stat-value">{{ userStats.totalPlayCount }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="isSeasonEnded && rewards" class="rewards-section">
                        <h3>Your Rewards</h3>
                        <div class="rewards-list">
                            <div v-for="(reward, index) in rewards" :key="index" class="reward-item">
                                <span class="reward-description">{{ reward }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="rankings">
                        <div v-if="topCountries" class="ranking-section">
                            <h3>Top Countries</h3>
                            <div class="ranking-list">
                                <div v-for="(country, index) in topCountries" :key="country.name"
                                    :class="['ranking-item', `rank-${index + 1}`]">
                                    <div class="rank-container">
                                        <span v-if="index < 3" class="medal">{{ getMedal(index) }}</span>
                                        <span v-else class="rank">{{ index + 1 }}</span>
                                    </div>
                                    <span class="country-flag">{{ getCountryFlag(country.name) }}</span>
                                    <span class="name">{{ country.name }}</span>
                                    <div class="stats">
                                        <div class="stat-item">
                                            <span class="stat-label">Score:</span>
                                            <span class="stat-value">{{ country.weightedScore.toFixed(1) }}</span>
                                        </div>
                                        <div class="stat-item">
                                            <span class="stat-label">Players:</span>
                                            <span class="stat-value">{{ country.userCount }}</span>
                                        </div>
                                        <div class="stat-item">
                                            <span class="stat-label">Plays:</span>
                                            <span class="stat-value">{{ country.totalPlayCount }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="topPlayers" class="ranking-section">
                            <h3>Top Players</h3>
                            <div class="ranking-list">
                                <div v-for="(player, index) in topPlayers" :key="player.name"
                                    :class="['ranking-item', `rank-${index + 1}`]">
                                    <div class="player-rank-container">
                                        <div class="rank-container">
                                            <div v-if="index < 3" class="trophy-wrapper">
                                                <img :src="getTrophyImage(index + 1)" :alt="`Trophy ${index + 1}`"
                                                    class="trophy-image">
                                                <span class="trophy-number">{{ index + 1 }}</span>
                                            </div>
                                            <span v-else class="rank">{{ index + 1 }}</span>
                                        </div>
                                        <span class="name">{{ player.name }}</span>
                                    </div>
                                    <div class="stats">
                                        <div class="stat-item">
                                            <span class="stat-label">Score:</span>
                                            <span class="stat-value">{{ player.weightedScore.toFixed(1) }}</span>
                                        </div>
                                        <div class="stat-item">
                                            <span class="stat-label">Plays:</span>
                                            <span class="stat-value">{{ player.totalPlayCount }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="closeRewards" class="close-button pixel-button">Close</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import gameService from '@/services/gameService';
import { useUserStore } from '@/stores/userStore';
import { useCountries } from '@/composables/useCountries';

export default {
    name: 'SeasonRewards',
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const userStats = ref({});
        const topCountries = ref([]);
        const topPlayers = ref([]);
        const rewards = ref([]);
        const loading = ref(true);
        const isSeasonEnded = ref(false);
        const userStore = useUserStore();
        const { countryFlagEmojis } = useCountries();

        const fetchData = async () => {
            if (!userStore.getUserId()) {
                return;
            }
            loading.value = true;
            try {
                const data = await gameService.fetchSeasonRewards(userStore.getUserId());
                userStats.value = {
                    ...data.userStats,
                    rank: data.userStats.rank || 'N/A'
                };
                topCountries.value = data.topCountries;
                topPlayers.value = data.topPlayers;
                rewards.value = data.rewards;
                isSeasonEnded.value = data.isSeasonEnded;
            } catch (error) {
                console.error('Failed to fetch season rewards:', error);
            } finally {
                loading.value = false;
            }
        };

        const closeRewards = () => {
            emit('close');
        };

        const getCountryFlag = (countryName) => {
            return countryFlagEmojis[countryName] || '🏳️';
        };

        const getTrophyImage = (rank) => {
            return require(`@/assets/trophy-${rank}.png`);
        };

        const getMedal = (index) => {
            const medals = ['🥇', '🥈', '🥉'];
            return medals[index];
        };

        watch(() => props.isVisible, (newValue) => {
            if (newValue) {
                fetchData();
            }
        });

        onMounted(() => {
            if (props.isVisible) {
                fetchData();
            }
        });

        return {
            userStats,
            topCountries,
            topPlayers,
            rewards,
            loading,
            isSeasonEnded,
            closeRewards,
            getCountryFlag,
            getTrophyImage,
            getMedal
        };
    }
}
</script>

<style scoped>
.season-rewards-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.season-rewards-modal {
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-dark);
    border-radius: 15px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    text-align: center;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.1);
    color: var(--color-text-light, #e0e0e0);
    transition: height 0.3s ease;
}

.season-rewards-modal.loading-state {
    height: auto;
    min-height: 200px;
}

h2 {
    color: var(--color-prize);
    font-size: 2em;
    margin-bottom: 15px;
}

h3 {
    color: var(--color-secondary);
    font-size: 1.4em;
    margin-bottom: 10px;
}

.rewards-content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 10px;
}

.user-stats {
    background-color: var(--color-bg-light);
    border-radius: 10px;
    padding: 15px;
}

.stats-display {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
}

.stat-label {
    color: var(--color-text);
    font-size: 0.8em;
}

.stat-value {
    color: var(--color-score);
    font-size: 1.2em;
    font-weight: bold;
}

.rankings {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ranking-section {
    width: 100%;
}

.ranking-list {
    background-color: var(--color-bg-light, #16213e);
    border-radius: 10px;
    padding: 10px;
}

.ranking-item {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    justify-content: space-between;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    transition: all 0.3s ease;
}

.ranking-item:last-child {
    border-bottom: none;
}

.ranking-item:hover {
    background-color: var(--color-bg-hover);
}

.rank-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.rank {
    font-weight: bold;
    color: var(--color-text-light, #e0e0e0);
}

.name {
    text-align: left;
    margin-left: 2px;
    font-size: 1.0em;
    width: 100px;
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-text-light, #e0e0e0);
}

.medal {
    font-size: 24px;
}

.country-flag {
    font-size: 30px;
    margin-right: 10px;
}

.trophy-wrapper {
    position: relative;
    width: 24px;
    height: 24px;
}

.trophy-image {
    width: 100%;
    height: 100%;
}

.trophy-number {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-text);
    font-size: 10px;
    font-weight: bold;
}

.close-button {
    margin-top: 20px;
    font-size: 1em;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 50%;
    align-self: center;
    max-width: 300px;
    transition: all 0.3s ease;
    letter-spacing: 1px;
}

.close-button:hover {
    transform: translateY(-2px);
}

.loading {
    font-size: 1.3em;
    color: var(--color-text-light, #e0e0e0);
    margin: 20px 0;
}

.stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    gap: 4px;
}

@media (max-width: 768px) {
    .season-rewards-modal {
        max-height: 90vh;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.4em;
    }

    .ranking-item {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .stat-item {
        align-items: center;
    }
}

.rank-1 {
    /* background-color: rgba(255, 215, 0, 0.3); */
    border: 2px solid gold;
    box-shadow: 0 0 10px gold;
}

.rank-2 {
    /* background-color: rgba(192, 192, 192, 0.9); */
}

.rank-3 {
    /* background-color: #CD7F32; */
}

.rewards-section {
    margin-top: 20px;
}

.rewards-list {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;
}

.reward-item {
    display: flex;
    align-items: center;
    background-color: var(--color-bg-light);
    border-radius: 8px;
    padding: 10px;
}

.reward-icon {
    font-size: 24px;
    margin-right: 10px;
}

.reward-description {
    flex-grow: 1;
    text-align: center;
}

.player-rank-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
</style>