<template>
    <div class="login-panel">
        <div v-if="isLoggedIn" class="user-info">
            <img :src="user.headPic" @click="toggleLogoutMenu" alt="User Avatar" class="user-avatar">
            <div class="user-details" @click="toggleLogoutMenu">
                <span class="user-name">{{ user.name || user.email }}</span>
            </div>
            <button class="reward-button pixel-button" @click="showRewards">Rewards</button>
        </div>

        <div v-else class="login-buttons">
            <button class="pixel-button signup-button" @click="login">Sign In</button>
        </div>
        <transition name="slide-down">
            <div v-if="showLogoutMenu" class="logout-menu">
                <button @click="logout" class="pixel-button logout-button">Logout</button>
            </div>
        </transition>
    </div>
</template>

<script>
import { useUserStore } from '../../stores/userStore'
import { ref, computed } from 'vue';

export default {
    name: 'LoginPanel',
    setup(props, { emit }) {
        const userStore = useUserStore()
        const showLogoutMenu = ref(false);

        const isLoggedIn = computed(() => {
            console.log("Checking login status:", userStore.isLoggedIn);
            return userStore.isLoggedIn;
        })
        const user = computed(() => {
            console.log("Current user:", userStore.user);
            return userStore.user;
        })

        const showRewards = () => {
            emit('showRewards')
        }

        const login = () => {
            userStore.showLock()
        }

        const logout = () => {
            userStore.logout()
            showLogoutMenu.value = false
        }

        const toggleLogoutMenu = () => {
            showLogoutMenu.value = !showLogoutMenu.value
        }

        return {
            isLoggedIn,
            user,
            showRewards,
            login,
            logout,
            showLogoutMenu,
            toggleLogoutMenu
        }
    }
}
</script>

<style scoped>
.login-panel {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
}

.user-info {
    cursor: pointer;
    display: flex;
    align-items: center;

}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    border: 2px solid var(--border-color);
}

.user-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.user-name {
    color: var(--color-text);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 4px;
    font-weight: bold;
}

.reward-button {
    background-color: var(--color-primary);
    color: var(--color-text);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
}


.login-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.pixel-button:hover {
    background-color: var(--color-primary-hover);
}

.pixel-button:active {
    box-shadow: 0 2px var(--shadow-color);
    transform: translateY(2px);
}

.pixel-button:disabled {
    cursor: not-allowed;
}

.login-button,
.signup-button {
    flex: 1;
    margin-right: 5px;
    margin-left: 5px;
}

.logout-button {
    width: 100%;
    background-color: var(--color-error);
}

.logout-button:hover {
    background-color: var(--color-error-hover);
}

.logout-button:active {
    box-shadow: 0 2px var(--color-error-hover);
}

.logout-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-bg);
    border-radius: 0 0 10px 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 10;
}

.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 0.3s ease;
}

.slide-down-enter-from,
.slide-down-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>