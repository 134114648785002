<template>
  <div v-if="isLoaded" class="countdown-timer">
    <div class="timer">
      <div class="timer-label">Next Round</div>
      <div class="timer-values">
        <div class="time-unit">
          <span class="timer-value">{{ formattedDays }}</span>
          <span class="time-label">DAYS</span>
        </div>
        <div class="time-unit">
          <span class="timer-value">{{ formattedHours }}</span>
          <span class="time-label">HOURS</span>
        </div>
        <div class="time-unit">
          <span class="timer-value">{{ formattedMinutes }}</span>
          <span class="time-label">MINUTES</span>
        </div>
        <div class="time-unit">
          <span class="timer-value">{{ formattedSeconds }}</span>
          <span class="time-label">SECONDS</span>
        </div>
      </div>
    </div>
    <!-- 隐藏 Prize Pool -->
    <!-- <div class="prize-pool">
            <span class="prize-label">Prize Pool:</span>
            <span class="prize-value">{{ formattedPrizePool }}</span>
        </div> -->
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import gameService from '../services/gameService';

export default {
  name: 'CountdownTimer',
  setup() {
    const endTime = ref(null);
    const prizePool = ref(0);
    const displayPrizePool = ref(0);
    const remainingTime = ref(0);
    const isLoaded = ref(false);
    let timer;
    let animationFrame;
    const animationDuration = 1000;

    const updateRemainingTime = () => {
      if (!endTime.value) return;
      const now = new Date();
      remainingTime.value = Math.max(0, endTime.value.getTime() - now.getTime());
      if (remainingTime.value === 0) {
        clearInterval(timer);
        onGameEnd();
      }
    };

    const onGameEnd = () => {
    };

    const fetchGameData = async () => {
      try {
        const gameData = await gameService.fetchGameData();
        if (gameData.endDate) {
          endTime.value = new Date(gameData.endDate);
          if (gameData.prizePoolAmount) {
            prizePool.value = gameData.prizePoolAmount;
          }
        }
        isLoaded.value = true;
        updateRemainingTime();
      } catch (error) {
        console.error('Failed to fetch game data:', error);
      }
    };

    const animatePrizePool = (start, end, duration) => {
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        displayPrizePool.value = Math.floor(start + (end - start) * progress);

        if (progress < 1) {
          animationFrame = requestAnimationFrame(animate);
        }
      };

      animationFrame = requestAnimationFrame(animate);
    };

    watch(prizePool, (newVal, oldVal) => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
      animatePrizePool(oldVal, newVal, animationDuration);
    });

    onMounted(async () => {
      await fetchGameData();
      timer = setInterval(updateRemainingTime, 1000);
    });

    onUnmounted(() => {
      clearInterval(timer);
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    });

    const days = computed(() => Math.floor(remainingTime.value / (1000 * 60 * 60 * 24)));
    const hours = computed(() => Math.floor((remainingTime.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutes = computed(() => Math.floor((remainingTime.value % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = computed(() => Math.floor((remainingTime.value % (1000 * 60)) / 1000));

    const formatTimeUnit = (unit) => {
      return unit < 10 ? `0${unit}` : unit;
    };

    const formattedDays = computed(() => formatTimeUnit(days.value));
    const formattedHours = computed(() => formatTimeUnit(hours.value));
    const formattedMinutes = computed(() => formatTimeUnit(minutes.value));
    const formattedSeconds = computed(() => formatTimeUnit(seconds.value));

    return {
      isLoaded,
      formattedDays,
      formattedHours,
      formattedMinutes,
      formattedSeconds
    };
  }
};
</script>

<style scoped>
.countdown-timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  margin-top: 10px;
  width: 100%;
  max-width: 70vw;
}

.timer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-label {
  margin-bottom: 10px;
  font-size: 1.5em;
  opacity: 0.8;
}

.timer-values {
  display: flex;
  align-items: center;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  padding-left: 12px;
  padding-right: 12px;
}

@media (max-width: 768px) {
  .time-unit {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.timer-value {
  color: var(--color-stats);
  font-size: 2em;
  font-weight: 300;
  font-family: 'Arial', sans-serif;
}

.time-label {
  font-size: 0.3em;
  opacity: 0.5;
}

/* 先隐藏 Prize Pool */
.prize-pool {
  display: none;
}

@media (max-width: 768px) {
  .timer-label {
    font-size: 1.2em;
  }

  .timer-value {
    font-size: 1.6em;
  }

  .countdown-timer {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  .timer-label {
    font-size: 1em;
  }

  .timer-value {
    font-size: 1.4em;
  }

  .countdown-timer {
    margin-top: 40px;
  }
}
</style>