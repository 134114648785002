<template>
    <div class="life-story">
        <h2 class="life-story-header">New Life Story</h2>
        <div v-if="!isComplete && !isLoading" class="story-controls">
            <button @click="toggleAutoPlay" class="control-button">
                {{ isAutoPlaying ? 'Pause' : 'Auto Play' }}
            </button>
            <button @click="toggleSpeed" class="control-button">
                {{ isSpeedUp ? 'Normal Speed' : 'Speed Up' }}
            </button>
            <button @click="showNextStory" class="control-button"
                :disabled="isAutoPlaying || currentStoryIndex >= life.stories.length">
                Continue
            </button>
        </div>
        <div v-if="isLoading" class="loading-spinner">
            <div class="pixel-rocket">
                <div class="rocket-body"></div>
                <div class="rocket-window"></div>
                <div class="rocket-fin"></div>
                <div class="rocket-fire"></div>
            </div>
            <p>Launching New Life...</p>
        </div>
        <div v-else-if="life && Object.keys(life).length > 0" class="life-content" ref="lifeContentRef">
            <ul class="stories-list">
                <li v-for="(story, index) in displayedStories" :key="index" class="story-item">
                    {{ story }}
                </li>
            </ul>

            <div v-if="isComplete" class="completion-container">
                <div class="completion-message">
                    <span class="completion-icon">🎉</span>
                    <span class="completion-text">Life Story Complete!</span>
                </div>
                <div class="life-score">
                    <!-- <div class="pixel-trophy"></div> -->
                    <span>Life Score: {{ life.score }}</span>
                </div>
                <h3 class="achievements-header">Achievements</h3>
                <div class="achievements-container">
                    <span v-for="(achievement, index) in life.achievements" :key="index" class="achievement-badge"
                        :style="{ backgroundColor: getAchievementColor(achievement) }">
                        {{ achievement }}
                    </span>
                </div>
                <vue3-lottie :animationData="confettiAnimation" :loop="false" :autoplay="true"
                    class="lottie-container" />
            </div>
        </div>
        <div class="modal-footer">
            <button @click="$emit('regenerate-new-life')" class="generate-button pixel-button">
                Restart a new life
            </button>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted, nextTick } from 'vue';
import { Vue3Lottie } from 'vue3-lottie'
import confettiAnimation from '@/assets/anim/celebration.json';

export default {
    name: 'LifeStory',
    components: {
        Vue3Lottie
    },
    props: {
        life: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    emits: ['regenerate-new-life', 'generate-new-life'],
    setup(props, { emit }) {
        const isLoading = ref(true);
        const displayedStories = ref([]);
        const currentStoryIndex = ref(0);
        const isAutoPlaying = ref(false);
        const isSpeedUp = ref(false);
        const isComplete = ref(false);
        let autoPlayInterval = null;
        const lifeContentRef = ref(null);

        const showNextStory = () => {
            if (currentStoryIndex.value < props.life.stories.length) {
                displayedStories.value.push(props.life.stories[currentStoryIndex.value]);
                currentStoryIndex.value++;

                nextTick(() => {
                    if (lifeContentRef.value) {
                        lifeContentRef.value.scrollTo({
                            top: lifeContentRef.value.scrollHeight,
                            behavior: 'smooth'
                        });
                    }
                });

                if (currentStoryIndex.value === props.life.stories.length) {
                    isComplete.value = true;
                    stopAutoPlay();
                }
            } else {
                stopAutoPlay();
            }
        };

        const initializeStory = () => {
            isLoading.value = false;
            displayedStories.value = [];
            currentStoryIndex.value = 0;
            isAutoPlaying.value = false;
            isSpeedUp.value = false;
            isComplete.value = false;
            if (props.life && props.life.stories && props.life.stories.length > 0) {
                showNextStory();
            }
        };

        watch(() => props.life, (newLife) => {
            console.log('newLife', newLife);
            if (newLife && Object.keys(newLife).length > 0) {
                initializeStory();
            }
        }, { immediate: true, deep: true });

        onMounted(() => {
            emit('generate-new-life');
        });

        const toggleAutoPlay = () => {
            if (isAutoPlaying.value) {
                stopAutoPlay();
            } else {
                startAutoPlay();
            }
        };

        const startAutoPlay = () => {
            isAutoPlaying.value = true;
            const interval = isSpeedUp.value ? 500 : 1500;
            autoPlayInterval = setInterval(() => {
                if (currentStoryIndex.value < props.life.stories.length) {
                    showNextStory();
                } else {
                    stopAutoPlay();
                }
            }, interval);
        };

        const stopAutoPlay = () => {
            isAutoPlaying.value = false;
            clearInterval(autoPlayInterval);
        };

        const toggleSpeed = () => {
            isSpeedUp.value = !isSpeedUp.value;
            if (isAutoPlaying.value) {
                stopAutoPlay();
                startAutoPlay();
            }
        };

        const getAchievementColor = (achievement) => {
            let hash = 0;
            for (let i = 0; i < achievement.length; i++) {
                hash = achievement.charCodeAt(i) + ((hash << 5) - hash);
            }
            const h = hash % 360;
            const s = 50; // 降低饱和度到50%
            const l = 40; // 降低亮度到40%
            return `hsl(${h}, ${s}%, ${l}%)`;
        };

        return {
            isLoading,
            displayedStories,
            currentStoryIndex,
            isAutoPlaying,
            isSpeedUp,
            isComplete,
            showNextStory,
            toggleAutoPlay,
            toggleSpeed,
            getAchievementColor,
            lifeContentRef,
            confettiAnimation
        };
    }
};
</script>

<style scoped>
.life-story {
    padding: 12px;
    color: var(--color-text);
    margin-bottom: 20px;
    width: 100%;
}

.life-content {
    height: 50vh;
    overflow-y: auto;
    padding-right: 10px;
    scroll-behavior: smooth;
}

.life-story-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-top: 0px;
    text-align: center;
}

h3 {
    text-align: center;
    margin-bottom: 20px;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.pixel-rocket {
    width: 64px;
    height: 64px;
    position: relative;
    animation: launch 2s infinite;
}

.rocket-body {
    position: absolute;
    width: 24px;
    height: 40px;
    background-color: var(--color-text);
    left: 20px;
    bottom: 12px;
}

.rocket-window {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: var(--color-bg);
    border: 2px solid var(--color-text);
    border-radius: 50%;
    left: 26px;
    bottom: 32px;
}

.rocket-fin {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid var(--color-text);
    left: 24px;
    bottom: 4px;
}

.rocket-fire {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #FF6B6B;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    left: 20px;
    bottom: -12px;
    animation: flicker 0.2s infinite;
}

@keyframes launch {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes flicker {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
    }
}

.completion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

.completion-text {
    font-size: 1.2rem;
    font-weight: bold;
}

.stories-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.story-item {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.story-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px;
}

.control-button {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.control-button:hover:not(:disabled) {
    background-color: var(--color-primary-hover);
}

.control-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.completion-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    font-size: 24px;
    font-weight: bold;
}

.completion-icon {
    font-size: 32px;
    margin-right: 10px;
}

.life-score {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 215, 0, 0.2);
    border: 2px solid gold;
    border-radius: 15px;
    padding: 12px 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    max-width: 250px;
    font-size: 24px;
    font-weight: bold;
    animation: glow 2s infinite alternate;
}

.pixel-trophy {
    width: 32px;
    height: 32px;
    background-color: gold;
    clip-path: polygon(50% 0%, 65% 15%, 100% 15%, 100% 35%, 85% 50%, 100% 65%, 100% 85%, 65% 85%, 50% 100%, 35% 85%, 0% 85%, 0% 65%, 15% 50%, 0% 35%, 0% 15%, 35% 15%);
    margin-right: 15px;
}

@keyframes glow {
    from {
        box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
    }

    to {
        box-shadow: 0 0 20px rgba(255, 215, 0, 0.8), 0 0 30px rgba(255, 215, 0, 0.6);
    }
}

.achievements-header {
    margin-top: 30px;
    margin-bottom: 0px;
}

.achievements-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.achievement-badge {
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    animation: popIn 0.5s ease forwards;
    opacity: 0;
    font-weight: bold;
    transform: scale(0.8);
}

@keyframes popIn {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-footer {
    width: 100%;
    margin-top: 20px;
    text-align: center;
}

.generate-button {
    width: 100%;
    max-width: 400px;
    height: 46px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: var(--font-weight-semibold);
    transition: background-color 0.3s;
}

.generate-button:hover {
    background-color: var(--color-primary-hover);
}

.lottie-container {
    width: 66%;
    height: 50%;
    margin: 20px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
}

@media (max-width: 768px) {
    .life-story {
        padding: 10px;
    }

    .story-controls {
        flex-direction: row;
    }

    .control-button {
        width: 100%;
        margin-bottom: 8px;
    }

    .life-score {
        font-size: 20px;
    }

    .achievement-badge {
        font-size: 12px;
    }
}
</style>