import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import '@/assets/main.css'
import lock from './auth/auth0-lock'
import { useUserStore } from './stores/userStore'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)

lock.on('authenticated', async (authResult) => {
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('id_token', authResult.idToken);
  
  const userStore = useUserStore()
  await userStore.fetchUserProfile(authResult.accessToken)
  
  // 关闭登录模态框
  userStore.closeLock()
});

app.mount('#app')

if (process.env.NODE_ENV === 'development') {
    console.log('Running in development mode')
} else if (process.env.NODE_ENV === 'production') {
    console.log('Running in production mode')
}