<template>
    <div class="expandable-panel" :class="{ 'expanded': isOpen }">
        <div class="panel-content" v-if="isOpen">
            <div class="close-button-container">
                <CloseButton @click="$emit('close')" />
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import CloseButton from './CloseButton.vue';

export default {
    name: 'ExpandablePanel',
    components: {
        CloseButton
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.expandable-panel {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transition: width 0.3s ease, background-color 0.3s ease;
    overflow: visible;
    z-index: 1000;
    width: 0;
    background-color: transparent;
}

.expandable-panel.expanded {
    width: 100%;
    background-color: var(--color-bg);
}

.panel-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
}

.close-button-container {
    align-self: flex-end;
    width: 32px;
    position: absolute;
    top: 12px;
    right: 12px;
    align-items: flex-end;
    justify-content: flex-end;
}
</style>