<template>
    <div id="world-map">
        <CountryStats v-if="hoveredCountryData" :countryData="hoveredCountryData" :position="adjustedMousePosition"
            @click.stop />
    </div>
</template>

<script>
import * as THREE from 'three';
import gsap from 'gsap';
import { filterCountryName } from '@/composables/utils';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { MapControls } from 'three/examples/jsm/controls/MapControls';
import CountryStats from './CountryStats.vue';
import gameService from '@/services/gameService';
import { useCountries } from '@/composables/useCountries';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { watch } from 'vue';
import { useSound } from '@/composables/useSound';

export default {
    name: 'WorldMapv2',
    components: {
        CountryStats
    },
    props: {
        minCountryArea: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            isMobile: false,
            selectedCountry: null,
            hoveredCountryData: null,
            mousePosition: { x: 0, y: 0 },
            audio: new Audio(require('@/assets/select2.wav')),
            containerWidth: 0,
            containerHeight: 0,
            highestScoringCountry: null,
            animationId: null,
            mapConfig: {
                zMin: 0,
                zMax: 10,
                minRadius: 1,
                maxRadius: 25,
                minScore: 0.1,
                highlightScale: 1.1,
                highlightColor: 0x0AB549,
                selectedColor: 0xFF0000,
                highlightEffectColor: 0xffd700,
                glowColor: 0xffd700,
                championColor: 0xFFDD50,
            }
        }
    },
    computed: {
        adjustedMousePosition() {
            const margin = 10;
            const maxX = this.containerWidth - 200;
            const maxY = this.containerHeight - 150;

            return {
                x: Math.min(Math.max(this.mousePosition.x, margin), maxX),
                y: Math.min(Math.max(this.mousePosition.y, margin), maxY)
            };
        }
    },
    mounted() {
        this.checkDeviceType();
        this.updateContainerSize();
        this.initEnv();
        this.initMap();
        window.addEventListener('resize', this.onWindowResize);
        this.animate = this.animate.bind(this);
        this.animate();
        watch(() => gameService.countryStats.value, () => {
            if (this.group && this.group.children.length > 0) {
                this.updateCountrySizes();
            }
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    unmounted() {
        if (this.animationId) {
            cancelAnimationFrame(this.animationId);
        }
        gsap.killTweensOf(this.group.children);
        this.cleanUpScene(this.scene);
        if (this.renderer) {
            this.renderer.dispose();
            this.renderer.forceContextLoss();
            this.renderer.domElement = null;
        }
        if (this.labelRenderer) {
            this.labelRenderer.domElement.remove();
            this.labelRenderer = null;
        }
        if (this.controls) {
            this.controls.dispose();
        }
        if (this.composer) {
            this.composer.dispose();
        }
        if (this.renderer && this.renderer.domElement) {
            this.renderer.domElement.removeEventListener('mousemove', this.hoverCountry);
            this.renderer.domElement.removeEventListener('mouseleave', this.resetHover);
            this.renderer.domElement.removeEventListener('click', this.onCountryClick);
        }
    },
    emits: ['countrySelected'],
    methods: {
        checkDeviceType() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        initEnv() {
            this.container = this.$el;
            this.scene = new THREE.Scene();

            this.camera = new THREE.PerspectiveCamera(50, this.containerWidth / this.containerHeight, 0.1, 1000);
            this.camera.position.set(0, 0, 220);
            this.camera.lookAt(new THREE.Vector3(0, 0, 0));

            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(this.containerWidth, this.containerHeight);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
            this.renderer.toneMappingExposure = 1.2;
            const style = getComputedStyle(document.documentElement);
            const backgroundColor = style.getPropertyValue('--color-bg').trim();
            const color = new THREE.Color(backgroundColor);
            this.renderer.setClearColor(color);
            this.$el.appendChild(this.renderer.domElement);

            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.containerWidth, this.containerHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            this.$el.appendChild(this.labelRenderer.domElement);

            this.group = new THREE.Group();

            this.controls = new MapControls(this.camera, this.renderer.domElement);
            this.controls.enableDamping = true;
            this.controls.dampingFactor = 0.25;
            this.controls.screenSpacePanning = true;
            this.controls.minDistance = 50;
            this.controls.maxDistance = 500;
            this.controls.maxPolarAngle = Math.PI / 2;

            this.scene.add(this.camera);

            this.raycaster = new THREE.Raycaster();
            this.hoveredCountry = null;
            this.renderer.domElement.addEventListener('mousemove', this.hoverCountry.bind(this));
            this.renderer.domElement.addEventListener('mouseleave', this.resetHover.bind(this));
            this.renderer.domElement.addEventListener('click', this.onCountryClick.bind(this));

            this.composer = new EffectComposer(this.renderer);
            this.composer.addPass(new RenderPass(this.scene, this.camera));
            this.composer.addPass(new SMAAPass(this.containerWidth, this.containerHeight));
        },
        async initMap() {
            const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
            this.scene.add(ambientLight);

            const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
            directionalLight.position.set(1, 1, 1);
            this.scene.add(directionalLight);

            const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 0.5);
            this.scene.add(hemisphereLight);

            const response = await fetch(process.env.VUE_APP_GEO_URL);
            const data = await response.json();

            await gameService.fetchCountryStats();
            const countryStats = gameService.countryStats.value;

            const maxWeightedScore = Math.max(...countryStats.map(country => country.weightedScore));

            this.highestScoringCountry = countryStats.reduce((prev, current) =>
                (prev.weightedScore > current.weightedScore) ? prev : current
            );

            data.features.forEach((feature) => {
                const countryName = this.getCountryName(feature);
                if (countryName === 'Antarctica') return;
                if (feature.geometry.type !== 'Polygon' && feature.geometry.type !== 'MultiPolygon') return;

                const countryData = countryStats.find(country => country.country === countryName);
                if (!countryData) return;

                let color = new THREE.Color(
                    Math.random(),
                    Math.random(),
                    Math.random()
                );
                if (this.getCountryName(feature) === this.highestScoringCountry.country) {

                    color = new THREE.Color(this.mapConfig.championColor);
                }

                const material = new THREE.MeshPhongMaterial({
                    color: color,
                    side: THREE.DoubleSide,
                    transparent: true,
                    opacity: 0.9,
                    emissive: color.clone().multiplyScalar(0.3),
                    specular: new THREE.Color(0x444444),
                    shininess: 50
                });
                this.createCountry(feature, material, countryData, maxWeightedScore);
            });

            this.group.scale.setScalar(1);
            this.scene.add(this.group);

            this.renderer.setSize(this.containerWidth, this.containerHeight);
            this.labelRenderer.setSize(this.containerWidth, this.containerHeight);
        },
        createCountry(feature, material, countryData, maxWeightedScore) {
            const countryName = this.getCountryName(feature);
            const center = this.calculateCenter(feature.geometry);

            const radius = this.calculateRadius(countryData.weightedScore, this.mapConfig.minScore, maxWeightedScore, this.mapConfig.minRadius, this.mapConfig.maxRadius);

            const zPosition = this.calculateZPosition(countryData.weightedScore, maxWeightedScore);
            const mesh = this.createCountryCircle(center, radius, material, this.group, countryName, countryData, zPosition);

            if (mesh) {
                this.group.add(mesh);
            }
        },
        calculateZPosition(weightedScore, maxWeightedScore) {
            // Invert the score so that lower scores are closer to the camera
            const invertedScore = maxWeightedScore - weightedScore;
            const normalizedScore = invertedScore / maxWeightedScore;

            // Map the normalized score to the z-range
            return this.mapConfig.zMin + normalizedScore * (this.mapConfig.zMax - this.mapConfig.zMin);
        },
        createCountryCircle(center, radius, material, group, countryName, countryData, zPosition) {
            const segments = 64;
            const geometry = new THREE.CircleGeometry(radius, segments);
            const mesh = new THREE.Mesh(geometry, material.clone());

            if (!mesh.material) {
                console.error('Failed to create material for country:', countryName);
                return null; // 如果无法创建材质，返回 null
            }

            mesh.material.needsUpdate = true;
            mesh.userData.originalColor = mesh.material.color.clone();
            mesh.userData.countryName = countryName;
            mesh.userData.center = new THREE.Vector3(center.x, center.y, zPosition);

            mesh.add(this.createLabel('country-flag', this.getCountryFlag(countryName)));
            mesh.add(this.createLabel('country-name', countryName));
            mesh.add(this.createLabel('country-stats', `${countryData.weightedScore.toFixed(1)}`));

            // 创建光晕
            const glowMaterial = this.createGlowMaterial();
            const glowGeometry = new THREE.CircleGeometry(radius * 1.2, 64);
            const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
            glowMesh.position.set(0, 0, -0.2);  // 将光晕放在圆圈后面
            glowMesh.visible = false;
            glowMesh.renderOrder = -1;
            mesh.add(glowMesh);
            mesh.userData.glowMesh = glowMesh;

            // if (countryData.weightedScore === this.highestScoringCountry.weightedScore) {
            //     this.highlightCountryEffect(mesh, true);
            // }

            mesh.position.set(center.x, center.y, zPosition);
            group.add(mesh);

            this.updateLabelPositions(mesh);

            this.animateScale(mesh, { x: 1, y: 1, z: 1 }, 1000, "elastic.out(1, 0.3)");

            return mesh; // 返回创建的网格
        },
        createGlowMaterial() {
            return new THREE.ShaderMaterial({
                uniforms: {
                    c: { type: "f", value: 0.1 },
                    p: { type: "f", value: 1.4 },
                    glowColor: { type: "c", value: new THREE.Color(this.mapConfig.glowColor) },
                    viewVector: { type: "v3", value: this.camera.position }
                },
                vertexShader: `
            uniform vec3 viewVector;
            varying vec3 vNormal;
            varying vec3 vWorldPosition;
            
            void main() {
                vNormal = normalize(normalMatrix * normal);
                vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                vWorldPosition = worldPosition.xyz;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
                fragmentShader: `
            uniform vec3 glowColor;
            uniform float c;
            uniform float p;
            varying vec3 vNormal;
            varying vec3 vWorldPosition;
            
            void main() {
                vec3 worldCameraToVertex = vWorldPosition - cameraPosition;
                vec3 viewVector = normalize(worldCameraToVertex);
                float intensity = pow(c - dot(vNormal, viewVector), p);
                
                vec2 center = vec2(0.5, 0.5);
                float distance = length(gl_PointCoord - center);
                float radialGradient = 1.0 - smoothstep(0.0, 0.5, distance);
                
                float finalIntensity = intensity * radialGradient;
                
                gl_FragColor = vec4(glowColor, finalIntensity);
            }
        `,
                side: THREE.BackSide,
                blending: THREE.AdditiveBlending,
                transparent: true,
                depthWrite: false,  // 不写入深度缓冲
                depthTest: true     // 但仍进行深度测试
            });
        },
        updateCountrySizes() {
            const countryStats = gameService.countryStats.value;
            const maxWeightedScore = Math.max(...countryStats.map(country => country.weightedScore));

            this.highestScoringCountry = countryStats.reduce((prev, current) =>
                (prev.weightedScore > current.weightedScore) ? prev : current
            );

            this.group.children.forEach(mesh => {
                const countryName = mesh.userData.countryName;
                const countryData = countryStats.find(country => country.country === countryName);

                if (countryData) {
                    const newRadius = this.calculateRadius(countryData.weightedScore, this.mapConfig.minScore, maxWeightedScore, this.mapConfig.minRadius, this.mapConfig.maxRadius);
                    const isHighestScoring = countryData.weightedScore === this.highestScoringCountry.weightedScore;
                    this.updateCountryMesh(mesh, newRadius, isHighestScoring, countryData);
                }
            });
        },
        updateCountryMesh(mesh, newRadius, isHighestScoring, countryData) {
            // 更新几何体
            mesh.geometry.dispose();
            mesh.geometry = new THREE.CircleGeometry(newRadius, 64);

            // 更新标签
            mesh.children.forEach(child => {
                if (child instanceof CSS2DObject && child.element.classList.contains('country-stats')) {
                    child.element.innerHTML = `${countryData.weightedScore.toFixed(1)}`;
                }
            });
            this.updateLabelPositions(mesh);

            // 更新高亮效果
            let highlightMesh = mesh.children.find(child => child.material && child.material.type === 'ShaderMaterial');
            if (isHighestScoring) {
                if (!highlightMesh) {
                    highlightMesh = this.highlightCountryEffect(mesh, true);
                } else {
                    highlightMesh.geometry.dispose();
                    highlightMesh.geometry = new THREE.CircleGeometry(newRadius * 1.2, 64);
                    if (highlightMesh.material.uniforms && highlightMesh.material.uniforms.radius) {
                        highlightMesh.material.uniforms.radius.value = newRadius;
                    }
                }
            } else if (highlightMesh) {
                mesh.remove(highlightMesh);
                highlightMesh.geometry.dispose();
                highlightMesh.material.dispose();
            }

            // 动画过渡到新的大小
            const startScale = mesh.geometry.parameters.radius / newRadius;
            mesh.scale.set(startScale, startScale, 1);
            gsap.to(mesh.scale, {
                x: 1,
                y: 1,
                z: 1,
                duration: 1,
                ease: "elastic.out(1, 0.3)"
            });
        },
        highlightCountryEffect(mesh, isHighest = false) {
            const highlightColor = isHighest ? new THREE.Color(this.mapConfig.highlightEffectColor) : new THREE.Color(this.mapConfig.highlightColor);
            const radius = mesh.geometry.parameters.radius;
            const highlightMaterial = this.createHighlightMaterial(highlightColor, radius);
            const highlightGeometry = new THREE.CircleGeometry(radius * 1.2, 64);  // 增加到 1.3 倍大小以确保覆盖范围
            const highlightMesh = new THREE.Mesh(highlightGeometry, highlightMaterial);
            // highlightMesh.position.set(0, 0, -0.01);
            mesh.add(highlightMesh);
            return highlightMesh;
        },
        createHighlightMaterial(color, radius) {
            return new THREE.ShaderMaterial({
                uniforms: {
                    color: { value: color },
                    radius: { value: radius }
                },
                vertexShader: `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }
                `,
                fragmentShader: `
                    uniform vec3 color;
                    uniform float radius;
                    varying vec2 vUv;
                    void main() {
                        float distance = length(vUv - vec2(0.5));
                        float alpha = smoothstep(0.5, 0.35, distance) * 0.5;
                        gl_FragColor = vec4(color, alpha);
                    }
                `,
                transparent: true,
                side: THREE.DoubleSide
            });
        },
        getCountryFlag(countryName) {
            const { countryFlagEmojis } = useCountries();
            return countryFlagEmojis[countryName] || '🏳️';
        },
        calculateCenter(geometry) {
            let coordinates;
            if (geometry.type === 'Polygon') {
                coordinates = geometry.coordinates[0];
            } else if (geometry.type === 'MultiPolygon') {
                coordinates = geometry.coordinates[0][0];
            }

            let sumX = 0;
            let sumY = 0;
            coordinates.forEach((coord) => {
                sumX += coord[0];
                sumY += coord[1];
            });
            const centerX = sumX / coordinates.length;
            const centerY = sumY / coordinates.length;
            return { x: centerX, y: centerY };
        },
        animate() {
            this.animationId = requestAnimationFrame(this.animate);
            this.controls.update();

            this.group.children.forEach(mesh => {
                if (mesh.userData.glowMesh && mesh.userData.glowMesh.visible) {
                    mesh.userData.glowMesh.material.uniforms.viewVector.value = new THREE.Vector3().subVectors(this.camera.position, mesh.position);
                }
            });

            this.composer.render();
            this.labelRenderer.render(this.scene, this.camera);
        },
        getCountryName(feature) {
            return filterCountryName(feature.properties.brk_name);
        },
        onWindowResize() {
            this.updateContainerSize();
            this.camera.aspect = this.containerWidth / this.containerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.containerWidth, this.containerHeight);
            this.labelRenderer.setSize(this.containerWidth, this.containerHeight);
            this.composer.setSize(this.containerWidth, this.containerHeight);
            this.updateAllLabelsPositions();
        },
        updateContainerSize() {
            this.containerWidth = this.$el ? this.$el.clientWidth : window.innerWidth;
            this.containerHeight = this.$el ? this.$el.clientHeight : window.innerHeight;
            if (this.isMobile) {
                this.containerHeight = this.containerWidth / 9 * 16;
            } else {
                this.containerHeight = this.containerWidth / 16 * 9;
            }
        },
        async hoverCountry(event) {
            event.preventDefault();
            const rect = this.renderer.domElement.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            this.mousePosition = { x, y };

            const intersects = this.getIntersects(x, y);

            if (intersects.length > 0) {
                const hoveredMesh = intersects[0].object;

                if (hoveredMesh && hoveredMesh.material) {
                    if (this.hoveredCountry && this.hoveredCountry !== hoveredMesh && this.hoveredCountry !== this.selectedCountry) {
                        this.resetCountry(this.hoveredCountry);
                    }

                    this.hoveredCountry = hoveredMesh;
                    if (this.hoveredCountry !== this.selectedCountry) {
                        this.highlightCountry(this.hoveredCountry);
                    }

                    const countryName = hoveredMesh.userData.countryName;
                    if (countryName) {
                        if (!this.isMobile) {
                            this.hoveredCountryData = gameService.getCountryStats(countryName);
                        }
                    }
                }
            } else if (this.hoveredCountry && this.hoveredCountry !== this.selectedCountry) {
                this.resetCountry(this.hoveredCountry);
                this.hoveredCountry = null;
                this.hoveredCountryData = null;
            }
        },
        resetHover() {
            if (this.hoveredCountry && this.hoveredCountry !== this.selectedCountry) {
                this.resetCountry(this.hoveredCountry);
                this.hoveredCountry = null;
                this.hoveredCountryData = null;
            }
        },
        getIntersects(x, y) {
            const mouse = new THREE.Vector2();
            mouse.x = (x / this.containerWidth) * 2 - 1;
            mouse.y = -(y / this.containerHeight) * 2 + 1;
            this.raycaster.setFromCamera(mouse, this.camera);
            return this.raycaster.intersectObjects(this.group.children, true);
        },
        highlightCountry(mesh) {
            if (!mesh || !mesh.material || !mesh.material.color) {
                // console.error('Invalid mesh, material, or color:', mesh);
                return;
            }

            const highlightColor = new THREE.Color(this.mapConfig.highlightColor);
            gsap.to(mesh.material.color, {
                r: highlightColor.r,
                g: highlightColor.g,
                b: highlightColor.b,
                duration: 0.3
            });

            if (mesh.userData.glowMesh) {
                mesh.userData.glowMesh.visible = true;
                gsap.to(mesh.userData.glowMesh.material.uniforms.c, {
                    value: 0.3,
                    duration: 1,
                    yoyo: true,
                    repeat: -1,
                    ease: "sine.inOut"
                });
            }
        },
        onCountryClick(event) {
            event.preventDefault();
            const rect = this.renderer.domElement.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const intersects = this.getIntersects(x, y);

            if (intersects.length > 0) {
                const clickedMesh = intersects[0].object;
                const countryName = clickedMesh.userData.countryName;
                if (countryName) {
                    this.$emit('countrySelected', countryName);
                    if (this.isMobile) {
                        this.hoveredCountryData = gameService.getCountryStats(countryName);
                    }
                    this.selectCountry(clickedMesh);
                }
            }
        },
        selectCountry(mesh) {
            if (this.selectedCountry) {
                this.toggleCountrySelection(this.selectedCountry, false);
            }
            this.selectedCountry = mesh;
            const { isMuted } = useSound();
            if (!isMuted.value) {
                this.audio.play();
            }
            this.toggleCountrySelection(mesh, true);
        },
        resetCountry(mesh) {
            if (mesh) {
                this.toggleCountrySelection(mesh, false);
            }
        },
        toggleCountrySelection(mesh, isSelected) {
            if (!mesh || !mesh.material || !mesh.material.color) {
                // console.error('Invalid mesh or material:', mesh);
                return;
            }

            const targetColor = isSelected ? new THREE.Color(this.mapConfig.selectedColor) :
                (mesh.userData.originalColor || mesh.material.color.clone());
            const targetScale = isSelected ? { x: this.mapConfig.highlightScale, y: this.mapConfig.highlightScale, z: this.mapConfig.highlightScale } : { x: 1, y: 1, z: 1 };

            gsap.to(mesh.material.color, {
                r: targetColor.r,
                g: targetColor.g,
                b: targetColor.b,
                duration: 0.3
            });

            gsap.to(mesh.scale, {
                ...targetScale,
                duration: 0.2,
                ease: "power2.out"
            });

            if (mesh.userData.glowMesh) {
                mesh.userData.glowMesh.visible = isSelected;
                if (isSelected) {
                    gsap.to(mesh.userData.glowMesh.material.uniforms.c, {
                        value: 0.3,
                        duration: 1,
                        yoyo: true,
                        repeat: -1,
                        ease: "sine.inOut"
                    });
                } else {
                    gsap.killTweensOf(mesh.userData.glowMesh.material.uniforms.c);
                }
            }
        },
        calculateRadius(score, minScore, maxScore, minRadius, maxRadius, base = 20) {
            const logMinScore = Math.log(minScore);
            const logMaxScore = Math.log(maxScore);
            const logScore = Math.log(Math.max(score, minScore));
            const t = (logScore - logMinScore) / (logMaxScore - logMinScore);
            return minRadius + (Math.pow(base, t) - 1) / (base - 1) * (maxRadius - minRadius);
        },
        updateLabelPositions(mesh) {
            const radius = mesh.geometry.parameters.radius;
            mesh.children.forEach(child => {
                if (child instanceof CSS2DObject) {
                    if (child.element.classList.contains('country-flag')) {
                        child.position.set(0, radius * -0.5, 0.1);
                        child.element.style.fontSize = `${radius * 2}px`;
                    } else if (child.element.classList.contains('country-name')) {
                        child.position.set(0, radius * -0.1, 0.1);
                        child.element.style.fontSize = `${radius * 0.8}px`;
                    } else if (child.element.classList.contains('country-stats')) {
                        child.position.set(0, radius * 0.4, 0.1);
                        child.element.style.fontSize = `${radius * 0.9}px`;
                    }
                }
            });
        },
        createLabel(className, content) {
            const div = document.createElement('div');
            div.className = className;
            div.textContent = content;
            div.style.color = 'white';
            return new CSS2DObject(div);
        },
        updateAllLabelsPositions() {
            this.group.children.forEach(mesh => {
                this.updateLabelPositions(mesh);
            });
        },
        animateScale(object, targetScale, duration, ease) {
            gsap.to(object.scale, {
                x: targetScale.x,
                y: targetScale.y,
                z: targetScale.z,
                duration: duration / 1000,
                ease: ease
            });
        },
        cleanUpScene(obj) {
            while (obj.children.length > 0) {
                this.cleanUpScene(obj.children[0]);
                obj.remove(obj.children[0]);
            }
            if (obj.geometry) obj.geometry.dispose();
            if (obj.material) {
                if (Array.isArray(obj.material)) {
                    obj.material.forEach(material => this.disposeMaterial(material));
                } else {
                    this.disposeMaterial(obj.material);
                }
            }
            if (obj.texture) obj.texture.dispose();
        },

        disposeMaterial(material) {
            for (const key of Object.keys(material)) {
                const value = material[key];
                if (value && typeof value.dispose === 'function') {
                    value.dispose();
                }
            }
            material.dispose();
        }
    }
};
</script>

<style scoped>
#world-map {
    width: 100%;
    height: 100%;
    position: relative;
}

.country-flag {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.country-name {
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
}

.country-stats {
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
    white-space: nowrap;
}

.country-flag,
.country-name,
.country-stats {
    pointer-events: none;
}
</style>