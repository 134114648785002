import { ref } from 'vue';

export function useToast() {
    const message = ref('');
    const type = ref('');
    const duration = ref(3000);
    const isVisible = ref(false);

    const showToast = (msg, toastType = 'info', toastDuration = 3000) => {
        message.value = msg;
        type.value = toastType;
        duration.value = toastDuration;
        isVisible.value = true;

        setTimeout(() => {
            isVisible.value = false;
        }, toastDuration);
    };

    return {
        message,
        type,
        duration,
        isVisible,
        showToast
    };
}