// src/composables/useEventBus.js

import { reactive } from 'vue'

const bus = reactive({
    events: {}
})

export function useEventBus() {
    const emit = (event, ...args) => {
        if (bus.events[event]) {
            bus.events[event].forEach(callback => callback(...args))
        }
    }

    const on = (event, callback) => {
        if (!bus.events[event]) {
            bus.events[event] = []
        }
        bus.events[event].push(callback)

        // 返回一个取消订阅的函数
        return () => {
            bus.events[event] = bus.events[event].filter(cb => cb !== callback)
        }
    }

    const off = (event, callback) => {
        if (bus.events[event]) {
            bus.events[event] = bus.events[event].filter(cb => cb !== callback)
        }
    }

    return { emit, on, off }
}