<template>
    <Transition name="toast">
        <div v-if="isVisible" class="toast-container" :class="type">
            <div class="toast-content">
                <component :is="icon" class="toast-icon" />
                <span class="toast-message">{{ message }}</span>
            </div>
            <button v-if="showCloseButton" @click="close" class="toast-close">
                <XMarkIcon class="h-5 w-5" />
            </button>
        </div>
    </Transition>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { XMarkIcon, ExclamationCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/vue/24/solid';

export default {
    name: 'ToastNotification',
    components: { XMarkIcon, ExclamationCircleIcon, CheckCircleIcon, InformationCircleIcon },
    props: {
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'info',
            validator: (value) => ['error', 'success', 'warning', 'info'].includes(value)
        },
        duration: {
            type: Number,
            default: 3000
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const isVisible = ref(props.isVisible);
        const icon = computed(() => {
            switch (props.type) {
                case 'error': return ExclamationCircleIcon;
                case 'success': return CheckCircleIcon;
                case 'warning': return ExclamationCircleIcon;
                case 'info': return InformationCircleIcon;
                default: return InformationCircleIcon;
            }
        });

        watch(() => props.isVisible, (newVal) => {
            isVisible.value = newVal;
        });

        watch(() => props.message, (newMessage) => {
            if (newMessage) {
                isVisible.value = true;
                if (props.duration > 0) {
                    setTimeout(() => {
                        close();
                    }, props.duration);
                }
            }
        });

        const close = () => {
            isVisible.value = false;
            emit('update:isVisible', false);
        };

        return {
            icon,
            close
        };
    }
};
</script>

<style scoped>
.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    max-width: 80vw;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 14px;
    line-height: 1.2;
}

.toast-content {
    display: flex;
    align-items: center;
}

.toast-icon {
    margin-right: 8px;
    font-size: 20px;
    width: 28px;
    height: 28px;
}

.toast-message {
    flex-grow: 1;
}

.toast-close {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 18px;
    width: 28px;
    height: 28px;
    opacity: 0.7;
    color: white;
    transition: opacity 0.2s;
}

.toast-close:hover {
    opacity: 1;
}

.error {
    background-color: var(--color-error);
}

.success {
    background-color: var(--color-success);
}

.warning {
    background-color: var(--color-warning);
}

.info {
    background-color: var(--color-primary);
}

.toast-enter-active,
.toast-leave-active {
    transition: all 0.3s ease;
}

.toast-enter-from,
.toast-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
</style>