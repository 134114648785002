<template>
  <div id="app" :class="['app-container']">
    <!-- <WorldMap class="map-container" @countrySelected="handleCountrySelected" :minCountryArea="2" /> -->
    <WorldMapv2 class="map-container" @countrySelected="handleCountrySelected" :minCountryArea="2" />
    <div class="main-header">
      <div class="header-buttons">
        <button @click="toggleMute" class="mute-button" :aria-label="isMuted ? 'Unmute' : 'Mute'">
          <svg v-if="isMuted" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path
              d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
          </svg>
          <svg v-else viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path
              d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
          </svg>
        </button>
        <button @click="openPanel" class="open-panel-button pixel-button ranking-button">
          <span class="fire-icon">🔥</span><span class="ranking-text">Ranking</span>
        </button>
      </div>
      <CountdownTimer />
    </div>
    <ExpandablePanel class="floating-panel" :isOpen="isPanelOpen" @close="closePanel">
      <LoginPanel @showRewards="openSeasonRewards" />
      <RankingTabs class="ranking" />
    </ExpandablePanel>
    <LifeModal v-if="isGameModalOpen" :isOpen="isGameModalOpen" @close="closeGameModal" />

    <div class="main-footer">
      <stats-board class="statsboard" />
      <RestartLifeButton v-if="isLoggedIn" class="main-button" @click="openGameModal">Restart Your Life
      </RestartLifeButton>
      <div v-else>
        <button class="start-game-button main-button pixel-button" @click="openLoginModal">Get Started</button>
      </div>

    </div>
    <a href="/privacy-policy.html" class="privacy-link">Privacy Policy</a>
    <ToastNotification :message="toastMessage" :type="toastType" :duration="toastDuration" :isVisible="toastVisible"
      @update:isVisible="toastVisible = $event" />
    <UserRankChangeNotification
      v-if="currentNotification && currentNotification.type === 'user' && currentNotification.changes"
      :changes="currentNotification.changes" />
    <CountryRankChangeNotification
      v-if="currentNotification && currentNotification.type === 'country' && currentNotification.changes"
      :changes="currentNotification.changes" />
    <RankingWidget />
    <SeasonRewards :isVisible="showSeasonRewards" @close="closeSeasonRewards" />
  </div>
</template>

<script>
// import WorldMap from './components/map/WorldMap.vue'
import WorldMapv2 from './components/map/WorldMapv2.vue'
import LoginPanel from './components/base/LoginPanel.vue'
import RankingTabs from './components/ranking/RankingTabs.vue'
import ExpandablePanel from './components/base/ExpandablePanel.vue'
import LifeModal from './components/life/LifeModal.vue'
import RestartLifeButton from './components/life/RestartLifeButton.vue'
import { useUserStore } from './stores/userStore'
import ToastNotification from './components/base/ToastNotification.vue'
import { useToast } from './composables/useToast'
import { provide, computed, toRef, ref, onMounted, onUnmounted, nextTick, watch } from 'vue';
import StatsBoard from './components/StatsBoard.vue'
import CountdownTimer from './components/CountdownTimer.vue'
import gameService from './services/gameService';
import RankingWidget from './components/ranking/RankingWidget.vue';
import SeasonRewards from './components/ranking/SeasonRewards.vue';
import CountryRankChangeNotification from './components/notification/CountryRankChangeNotification.vue';
import UserRankChangeNotification from './components/notification/UserRankChangeNotification.vue';
import { useNotificationQueue } from './composables/useNotificationQueue';
import { useSound } from './composables/useSound';

export default {
  name: 'App',
  components: {
    // WorldMap,
    WorldMapv2,
    LoginPanel,
    RankingTabs,
    ExpandablePanel,
    RestartLifeButton,
    LifeModal,
    ToastNotification,
    StatsBoard,
    CountdownTimer,
    RankingWidget,
    SeasonRewards,
    CountryRankChangeNotification,
    UserRankChangeNotification
  },
  data() {
    return {
      isPanelOpen: false,
      isGameModalOpen: false,
    }
  },
  setup() {
    const userStore = useUserStore();
    userStore.checkLoginStatus();
    const selectedCountry = ref('');
    const { message, type, duration, showToast, isVisible } = useToast();
    provide('showToast', showToast);
    const isGameActive = ref(false);
    const showSeasonRewards = ref(false);
    const { currentNotification } = useNotificationQueue();
    const { isMuted, toggleMute } = useSound();

    const isLoggedIn = computed(() => userStore.isLoggedIn);
    const userId = computed(() => userStore.getUserId());
    const toastVisible = ref(isVisible);

    const handleCountrySelected = (countryName) => {
      userStore.setSelectedCountry(countryName)
      console.log('Country selected:', countryName);
      selectedCountry.value = countryName;
    };

    const checkSeasonRewards = async () => {
      const userId = userStore.getUserId();
      if (userId) {
        const result = await gameService.checkSeasonStatus(userId);
        if (result && result.isSeasonEnded) {
          nextTick(() => {
            showSeasonRewards.value = true;
          });
        }
      }
    };
    const openSeasonRewards = () => {
      showSeasonRewards.value = true;
    };
    const closeSeasonRewards = () => {
      showSeasonRewards.value = false;
    };
    watch(userId, (newVal) => {
      if (newVal) {
        checkSeasonRewards();
      }
    });

    onMounted(() => {
      const result = gameService.fetchGameData();
      if (result) {
        isGameActive.value = true;
      }
      gameService.startRankingUpdates();
    });

    onUnmounted(() => {
      gameService.stopRankingUpdates();
    });

    const openLoginModal = () => {
      userStore.showLock()
    }

    return {
      isLoggedIn,
      toastMessage: toRef(message),
      toastType: toRef(type),
      toastDuration: toRef(duration),
      handleCountrySelected,
      selectedCountry,
      toastVisible,
      isGameActive,
      userStore,
      showToast,
      showSeasonRewards,
      openSeasonRewards,
      closeSeasonRewards,
      checkSeasonRewards,
      currentNotification,
      toggleMute,
      isMuted,
      openLoginModal
    }
  },
  methods: {
    openPanel() {
      this.isPanelOpen = true;
    },
    closePanel() {
      this.isPanelOpen = false;
    },
    openGameModal() {
      if (this.isGameActive) {
        this.isGameModalOpen = true;
      } else {
        this.showToast('Wait for the next round to start', 'warning', 1500);
      }
    },


    closeGameModal() {
      this.isGameModalOpen = false;
    }
  }
}
</script>

<style>
.app-container {
  background-color: var(--color-bg);
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.map-container {
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  transition: flex 0.3s ease;
}

.open-panel-button {
  position: fixed;
  top: 20px;
  right: 0px;
  width: 140px;
  height: 46px;
  z-index: 999;
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  justify-content: center;
  gap: 5px;
}

.open-rewards-button {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 140px;
  height: 46px;
  z-index: 999;
}

.ranking-text {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .map-container {
    margin-top: 13vh;
    margin-bottom: 16vh;
  }

  .ranking-text {
    font-size: 0.75rem;
  }

  .open-panel-button {
    top: 10px;
    right: 10px;
    width: 90px;
    height: 40px;
  }

  .selected-country-text {
    top: 75%;
    font-size: 1.0em;
  }
}

@media (max-width: 480px) {
  .map-container {
    margin-top: 16vh;
    margin-bottom: 16vh;
  }

  .open-panel-button {
    top: 10px;
    right: 10px;
    width: 90px;
    height: 40px;
  }

  .ranking-text {
    font-size: 0.75rem;
  }

  .selected-country-text {
    top: 75%;
    font-size: 1.0em;
  }
}

.floating-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 420px;
  height: 100vh;
  z-index: 1000;
  padding-top: 20px;
}


.fire-icon {
  font-size: 1.2em;
  /* Makes the fire icon slightly larger */
}


#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

.ranking {
  margin-top: 20px;
  padding: 0px;
}

.main-header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.header-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  justify-content: flex-end;
  gap: 2px;
}

.open-panel-button {
  position: relative;
  width: 140px;
  height: 46px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.mute-button {
  position: relative;
  width: 40px;
  top: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.mute-button:hover {
  background-color: var(--color-primary);
  color: var(--color-bg);
}

.mute-button:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .main-header {
    padding: 4px;
  }

  .header-buttons {
    gap: 2px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    justify-content: flex-end;
  }

  .open-panel-button {
    width: 90px;
    height: 36px;
    margin-right: 0px;
  }

  .mute-button {
    width: 36px;
    top: 10px;
    margin-right: 8px;
  }

  .ranking-text {
    font-size: 0.9rem;
  }
}

.main-footer {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
  padding: 10px;
}

.selected-country-text {
  position: fixed;
  width: 80%;
  max-width: 400px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;
  color: var(--color-text);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  z-index: 100;
  text-align: center;
}


.ranking-button {
  border: none;
  font-size: 1.1em !important;
  font-weight: var(--font-weight-normal);
}

.main-button {
  font-size: 1.2em !important;
  font-weight: var(--font-weight-bold);
  padding: 15px 40px;
  margin-bottom: 20px;
}

.mute-button {
  z-index: 200;
  width: 40px;
  height: 40px;
  padding: 8px;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  color: var(--color-text);
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mute-button:hover {
  background-color: transparent;
  color: var(--color-text);
}

.mute-button:focus {
  background-color: transparent;
}

.mute-button:active {
  background-color: transparent;
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .mute-button {
    bottom: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    padding: 6px;
  }
}

.privacy-link {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #555555;
  font-size: 0.7em;
  text-decoration: none;
  visibility: hidden;
}
</style>
