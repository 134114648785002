<template>
    <transition name="slide-fade">
        <div v-if="isVisible" class="rank-change-notification user-rank-change">
            <div v-for="change in changes" :key="change.country" class="change-item">
                <div class="message">{{ getNotificationMessage(change) }}</div>
            </div>
        </div>
    </transition>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'UserRankChangeNotification',
    props: {
        changes: {
            type: Array,
            required: true
        }
    },
    setup() {
        const isVisible = ref(false);

        const showNotification = () => {
            isVisible.value = true;
            setTimeout(() => {
                isVisible.value = false;
            }, 3000);
        };

        const getNotificationMessage = (change) => {
            const messages = [
                `🏆 ${change.name} just leveled up to #${change.newRank}!`,
                `🚀 ${change.name} is skyrocketing to #${change.newRank}!`,
                `🌟 ${change.name} just became a superstar at #${change.newRank}!`,
                `🎉 ${change.name} crashed the top 3 party!`,
                `👑 All hail ${change.name}, our new #${change.newRank} champion!`
            ];
            return messages[Math.floor(Math.random() * messages.length)];
        };

        onMounted(() => {
            showNotification();
        });

        return {
            isVisible,
            getNotificationMessage
        };
    }
};
</script>

<style scoped>
.rank-change-notification {
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 25px;
    border-radius: 10px;
    z-index: 9999;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    border: 2px solid #fff;
}

.user-rank-change {
    /* background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%); */
}

.change-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 1.2em;
    font-weight: bold;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px) translateX(-50%);
    opacity: 0;
}
</style>