<template>
    <div class="options-selection-parent">
        <p class="options-header">You are reborn in</p>
        <div v-if="selectedCountry" class="selected-country">
            <span class="country-flag">{{ getCountryFlag(selectedCountry) }}</span>
            <span class="country-name">{{ selectedCountry }}</span>
        </div>
        <div class="search-container">
            <input v-model="searchQuery" placeholder="Search countries..." />
        </div>

        <div v-if="isLoading" class="loading">Loading countries...</div>
        <div v-else class="options-selection-container">
            <TransitionGroup name="option-list" tag="div" class="options-grid">
                <button v-for="country in filteredCountries" :key="country" @click="selectCountry(country)"
                    :class="['option-button', { selected: country === selectedCountry }]">
                    <span class="country-flag">{{ getCountryFlag(country) }}</span>
                    <span class="country-name">{{ country }}</span>
                </button>
            </TransitionGroup>
        </div>
        <div class="options-confirm-button-container">
            <button @click="confirmSelection" class="options-confirm-button pixel-button" :disabled="!selectedCountry">
                Confirm
            </button>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useCountries } from '@/composables/useCountries';
import '@/assets/selection.css';

export default {
    name: 'CountrySelection',
    props: {
        initialCountry: {
            type: String,
            default: ''
        }
    },
    emits: ['country-selected'],
    setup(props, { emit }) {
        const { countries, countryFlagEmojis } = useCountries();
        const selectedCountry = ref(props.initialCountry || 'United States');
        const searchQuery = ref('');
        const isLoading = ref(true);

        const filteredCountries = computed(() => {
            if (!searchQuery.value) return countries.value || [];
            return (countries.value || []).filter(country =>
                country.toLowerCase().includes(searchQuery.value.toLowerCase())
            );
        });

        watch([countries, countryFlagEmojis], ([newCountries, newEmojis]) => {
            if (newCountries && newCountries.length > 0 && newEmojis) {
                isLoading.value = false;
                if (!selectedCountry.value) {
                    selectedCountry.value = getDefaultCountry();
                }
            }
        });

        const getDefaultCountry = () => {
            return 'United States';
        };

        const selectCountry = (country) => {
            selectedCountry.value = country;
        };

        const confirmSelection = () => {
            if (selectedCountry.value) {
                emit('country-selected', selectedCountry.value);
            }
        };

        const getCountryFlag = (countryName) => {
            return (countryFlagEmojis && countryFlagEmojis[countryName]) || '🏳️';
        };

        onMounted(() => {
            if (countries.value && countries.value.length > 0 && countryFlagEmojis) {
                isLoading.value = false;
                if (!selectedCountry.value) {
                    selectedCountry.value = countries.value[0];
                }
            }
        });

        return {
            selectedCountry,
            searchQuery,
            filteredCountries,
            selectCountry,
            confirmSelection,
            getCountryFlag,
            isLoading
        };
    }
};
</script>

<style scoped>
.options-header {
    background-color: transparent;
}

.option-button {
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.selected-country {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 94%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 4px;
    margin-top: 12px;
    color: white;
    border-radius: 8px;
    font-size: 1.1rem;
    border: 2px solid var(--color-primary);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .selected-country {
        width: 92%;
    }
}

.selected-country:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.selected-country .country-flag {
    font-size: 28px;
    margin-right: 10px;
}

.search-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}

input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 2px solid var(--color-border);
    border-radius: 8px;
    background-color: var(--color-bg-light);
    color: var(--color-text);
    transition: all 0.3s ease;
    margin-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
}

input:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.2);
}

.country-flag {
    font-size: 24px;
    margin-right: 0px;
}

.country-name {
    text-align: center;
}


.loading {
    font-size: 1.2rem;
    color: var(--color-text);
    text-align: center;
    margin-top: 20px;
}
</style>