<template>
    <button @click="$emit('click')" class="start-game-button pixel-button">
        <slot>Start Game</slot>
    </button>
</template>

<script>
export default {
    name: 'RestartLifeButton'
}
</script>

<style scoped>

</style>