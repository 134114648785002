<template>
    <div class="ranking-container">
        <button @click="refreshRankings" class="refresh-button">
            <i class="fas fa-sync-alt" :class="{ 'spin': isRefreshing }"></i>
        </button>
        <ul class="ranking-list">
            <li v-for="user in users" :key="user.username" :class="['ranking-item', `rank-${user.rank}`]">
                <div class="rank-container">
                    <!-- <span class="rank">{{ user.rank }}</span> -->
                    <div v-if="user.rank <= 3" class="trophy-wrapper">
                        <img :src="getTrophyImage(user.rank)" :alt="`Trophy ${user.rank}`" class="trophy-image">
                        <span class="trophy-number">{{ user.rank }}</span>
                    </div>
                </div>
                <span class="user-name">{{ user.username }}</span>
                <span class="user-score">{{ formatNumber(user) }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { rankingService } from '@/services/rankingService'

export default {
    name: 'UserRanking',
    data() {
        return {
            users: [],
            currentPage: 0,
            totalPages: 1,
            isRefreshing: false
        }
    },
    created() {
        this.fetchUserRankings()
    },
    methods: {
        async fetchUserRankings() {
            try {
                const result = await rankingService.getAllUsersRanking(this.currentPage)
                this.users = result.rankings
                this.totalPages = result.totalPages
            } catch (error) {
                console.error('Error fetching user rankings:', error)
                // You can add error handling logic here
            }
        },
        async refreshRankings() {
            this.isRefreshing = true
            await this.fetchUserRankings()
            this.isRefreshing = false
        },
        formatNumber(user) {
            const score = user.weightedScore
            return score.toFixed(0)
        },
        getTrophyImage(rank) {
            return require(`@/assets/trophy-${rank}.png`)
        }
    }
}
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';

.ranking-container {
    max-height: calc(80vh - 50px);
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
}

.refresh-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    color: var(--color-text);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.refresh-button i {
    font-size: 22px;
}


.refresh-button .spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ranking-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    height: 100%;
    margin-top: 40px;
    /* Adjust for refresh button */
}

.ranking-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease;
}

.ranking-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rank-1 {
    background-color: rgba(255, 215, 0, 0.3);
    border: 2px solid gold;
    box-shadow: 0 0 10px gold;
}

.rank-2 {
    /* background-color: rgba(192, 192, 192, 0.9); */
    border: 2px solid rgba(192, 192, 192, 0.9);
    /* border: 2px solid silver; */
    /* box-shadow: 0 0 8px silver; */
}

.rank-3 {
    /* background-color: #CD7F32; */
    border: 2px solid #CD7F32;
    /* border: 2px solid #CD7F32; */
    /* box-shadow: 0 0 6px #CD7F32; */
}

.rank-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    position: relative;
}

.rank {
    font-weight: bold;
    color: var(--color-text);
}

.trophy-wrapper {
    position: relative;
    width: 28px;
    height: 28px;
}

.trophy-image {
    width: 100%;
    height: 100%;
}

.trophy-number {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-text);
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
}

.user-name {
    flex-grow: 1;
    color: var(--color-text);
    font-weight: 500;
}

.user-score {
    font-weight: bold;
    color: var(--color-text);
    font-size: 1.1em;
}

@media (max-width: 600px) {

    .user-name {
        width: 100%;
        margin: 5px 0;
    }
}
</style>