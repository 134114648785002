import { ENDPOINTS } from './endpoints';
import { api } from '../foundation/api';

export const rankingService = {
    async getWorldRanking(limit = 10) {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.RANKING.GET_WORLD}`, { params: { limit } });
        return response;
    },

    async getAllUsersRanking(page = 1, limit = 50) {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.RANKING.GET_ALL_USERS}`, { params: { page, limit } });
        return response;
    },

    async getUserRank(userId) {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.RANKING.GET_USER}/${userId}`);
        return response;
    }
};