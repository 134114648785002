<template>
    <div class="ranking-container">
        <button @click="refreshRankings" class="refresh-button">
            <i class="fas fa-sync-alt" :class="{ 'spin': isRefreshing }"></i>
        </button>
        <ul v-if="countries && countries.length > 0" class="ranking-list">
            <li v-for="(country, index) in countries" :key="country.country"
                :class="['ranking-item', `rank-${index + 1}`]">
                <div class="rank-container">
                    <span v-if="index < 3" class="medal">{{ getMedal(index) }}</span>
                    <span v-else class="rank">{{ index + 1 }}</span>
                </div>
                <span class="country-flag">{{ getCountryFlag(country.country) }}</span>
                <span class="country-name">{{ country.country }}</span>
                <span class="country-score">{{ formatNumber(country) }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import gameService from '@/services/gameService'
import { useCountries } from '../../composables/useCountries'
import { ref } from 'vue'

export default {
    name: 'CountryRanking',
    setup() {
        const { countryFlagEmojis } = useCountries()
        const countries = ref(gameService.countryStats)
        const getCountryFlag = (countryName) => {
            return countryFlagEmojis[countryName] || '🏳️'
        }

        const getMedal = (index) => {
            const medals = ['🥇', '🥈', '🥉']
            return medals[index]
        }

        const formatNumber = (country) => {
            const score = country.weightedScore
            return score.toFixed(0)
        }

        return {
            getCountryFlag,
            getMedal,
            formatNumber,
            countries
        }
    },
    data() {
        return {
            isRefreshing: false
        }
    },
    created() {
        this.fetchCountryRankings()
    },
    methods: {
        async fetchCountryRankings() {
            try {
                const result = await gameService.fetchCountryRankings()
                this.countries.value = result
            } catch (error) {
                console.error('Error fetching country rankings:', error)
            }
        },
        async refreshRankings() {
            this.isRefreshing = true
            await this.fetchCountryRankings()
            this.isRefreshing = false
        }
    }
}
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';

.ranking-container {
    max-height: calc(80vh - 50px);
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
}

.refresh-button {
    position: absolute;
    top: 5px;
    right: 10px;
    color: var(--color-text);
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.refresh-button i {
    font-size: 22px;
}

.refresh-button .spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ranking-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    height: 100%;
    margin-top: 40px;
    /* Adjust for refresh button */
}

.ranking-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease;
}

.ranking-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rank-1 {
    background-color: rgba(255, 215, 0, 0.3);
    border: 2px solid gold;
    box-shadow: 0 0 10px gold;
}

.rank-2 {
    /* background-color: rgba(192, 192, 192, 0.9); */
    border: 2px solid rgba(192, 192, 192, 0.9);
}

.rank-3 {
    /* background-color: #CD7F32; */
    border: 2px solid #CD7F32;
}

.rank-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.rank {
    color: var(--color-text);
    font-weight: bold;
}

.medal {
    font-size: 34px;
}

.country-flag {
    font-size: 24px;
    margin-right: 15px;
}

.country-name {
    flex-grow: 1;
    color: var(--color-text);
    font-weight: 500;
}

.country-score {
    font-weight: bold;
    color: var(--color-text);
    font-size: 1.1em;
}

/* Add responsive design */
@media (max-width: 600px) {

    .country-name {
        width: 100%;
        margin: 5px 0;
    }
}
</style>