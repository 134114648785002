<template>
    <button class="close-button" @click="handleClick" aria-label="Close">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="close-button-icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </button>
</template>
<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['click']);

const handleClick = (event) => {
    emit('click', event);
};
</script>

<style scoped>
.close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    width: 32px;
    height: 32px;
    background-color: #333;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    transition: background-color 0.3s ease;
}

.close-button:hover {
    background-color: var(--color-primary);
}

.close-button-icon {
    color: #f1f1f1;
    width: 1.2rem;
    height: 1.2rem;
}

.close-button:hover svg {
    color: #fff;
}
</style>