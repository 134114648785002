import axios from 'axios';

class Api {
    constructor() {
        this.baseURL = process.env.VUE_APP_API_DOMAIN || 'http://localhost:3000/api';
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.initializeInterceptors();
    }

    initializeInterceptors() {
        this.instance.interceptors.request.use(
            (config) => {
                // 在发送请求之前做些什么
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                // 对请求错误做些什么
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (response) => {
                // 对响应数据做点什么
                return response;
            },
            (error) => {
                // 对响应错误做点什么
                if (error.response && error.response.status === 401) {
                    // 处理未授权错误，例如重定向到登录页面
                    // router.push('/login');
                }
                return Promise.reject(error);
            }
        );
    }

    async get(url, config) {
        const response = await this.instance.get(url, config);
        return response.data;
    }

    async post(url, data, config) {
        const response = await this.instance.post(url, data, config);
        return response.data;
    }

    async put(url, data, config) {
        const response = await this.instance.put(url, data, config);
        return response.data;
    }

    async delete(url, config) {
        const response = await this.instance.delete(url, config);
        return response.data;
    }
}

export const api = new Api();