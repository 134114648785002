import { api } from '../foundation/api';
import { ENDPOINTS } from './endpoints';

export const userService = {
    async getUser(userId) {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.USER.GET}/${userId}`);
        return response;
    },

    async getUserById(userId) {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.USER.GET}/${userId}`);
        return response;
    },
    async getUserByAuth0Id(auth0Id) {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.USER.GET_BY_MEMID}/${auth0Id}`);
        return response;
    },
    async createUser(data) {
        const response = await api.post(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.USER.CREATE}`, {
            userId: data.auth0Id,
            auth0Id: data.auth0Id,
            email: data.email,
            username: data.username,
            headPic: data.headPic,
        });
        return response;
    }
};