<template>
    <transition name="fade">
        <div v-if="countryData" class="country-info" :style="{ top: `${position.y}px`, left: `${position.x}px` }">
            <div class="country-header">
                <span class="country-flag">{{ getCountryFlag(countryData.country) }}</span>
                <h3>{{ countryData.country }}</h3>
            </div>
            <div class="stats-container">
                <div class="stat-item play-count">
                    <div class="stat-content">
                        <span class="stat-label">Play Count</span>
                        <span class="stat-value">{{ formatNumber(countryData.totalPlayCount) }}</span>
                    </div>
                </div>
                <div class="stat-item user-count">
                    <div class="stat-content">
                        <span class="stat-label">User Count</span>
                        <span class="stat-value">{{ formatNumber(countryData.userCount) }}</span>
                    </div>
                </div>
                <div class="stat-item score">
                    <div class="stat-content">
                        <span class="stat-label">Score</span>
                        <span class="stat-value">{{ countryData.weightedScore.toFixed(2) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { useCountries } from '@/composables/useCountries';

export default {
    name: 'CountryStats',
    props: {
        countryData: {
            type: Object,
            required: true
        },
        position: {
            type: Object,
            required: true
        }
    },
    setup() {
        const { countryFlagEmojis } = useCountries();

        const getCountryFlag = (countryName) => {
            return countryFlagEmojis[countryName] || '🏳️';
        };

        const formatNumber = (num) => {
            return num.toLocaleString();
        };

        return {
            getCountryFlag,
            formatNumber
        };
    }
}
</script>

<style scoped>
.country-info {
    position: absolute;
    background-color: rgba(25, 25, 35, 0.95);
    color: #ffffff;
    padding: 15px;
    border-radius: 12px;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 280px;
    min-width: 120px;
}

.country-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 8px;
}

.country-flag {
    font-size: 2em;
    margin-right: 10px;
}

.country-info h3 {
    margin: 0;
    font-size: 1.2em;
    font-weight: 600;
    color: #ffffff;
}

.stats-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stat-item {
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
}

.stat-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.stat-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.stat-label {
    font-size: 0.8em;
    color: #a0a0a0;
    font-weight: 500;
}

.stat-value {
    font-size: 1em;
    font-weight: 600;
    color: #ffffff;
}

.play-count {
    background-color: rgba(76, 175, 80, 0.2);
}

.user-count {
    background-color: rgba(33, 150, 243, 0.2);
}

.score {
    background-color: rgba(255, 193, 7, 0.2);
}

.score .stat-value {
    color: #ffc107;
    font-size: 1.2em;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

@media (min-width: 768px) {
    .country-info {
        padding: 20px;
        width: auto;
    }

    .country-flag {
        font-size: 2.8em;
        margin-right: 15px;
    }

    .country-info h3 {
        font-size: 1.6em;
    }

    .stats-container {
        gap: 15px;
    }

    .stat-item {
        padding: 10px;
    }

    .stat-label {
        font-size: 0.9em;
    }

    .stat-value {
        font-size: 1.2em;
    }

    .score .stat-value {
        font-size: 1.4em;
    }
}
</style>