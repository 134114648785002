import { api } from '../foundation/api';
import { ENDPOINTS } from './endpoints';

export const characterService = {
    async getTalents() {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.SEED.TALENTS}`);
        return response.talents;
    },

    async getRoles() {
        const response = await api.get(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.SEED.ROLES}`);
        return response.roles;
    }
};