import { api } from '../foundation/api';
import { ENDPOINTS } from './endpoints';

export const restartLifeService = {
    async restartLife(userId, properties, talents, role, userName, country) {
        try {
            const response = await api.post(`${process.env.VUE_APP_API_DOMAIN}/${ENDPOINTS.RESTART_LIFE}/${userId}`, {
                properties: properties,
                userName: userName,
                country: country,
                talents: talents,
                role: role
            });
            return response.life;
        } catch (error) {
            console.error('Error restarting life:', error);
            throw error;
        }
    }
};

