<template>
    <div class="options-selection-parent">
        <p class="options-header">Choose Your Talents</p>
        <div v-if="isLoading" class="loading-container">
            <Vue3Lottie :animationData="loadingAnimation" :height="200" :width="200" />
        </div>
        <div v-else class="options-selection-container">
            <TransitionGroup name="option-list" tag="div" class="options-grid">
                <button v-for="(talent, index) in talents" :key="talent._id" @click="toggleTalent(talent)"
                    :class="['option-button', { selected: isSelected(talent) }]"
                    :disabled="selectedTalents.length >= 3 && !isSelected(talent)"
                    :style="{ animationDelay: `${index * 50}ms` }">
                    <span :class="['talent-rarity', talent.level]">{{ talent.level }}</span>{{ talent.name }}
                </button>
            </TransitionGroup>
        </div>
        <div class="options-confirm-button-container">
            <button @click="confirmSelection" :disabled="selectedTalents.length === 0"
                class="options-confirm-button pixel-button">
                Start
            </button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { characterService } from '@/services/characterService';
import loadingAnimationData from '@/assets/anim/loading-hand.json';
import { Vue3Lottie } from 'vue3-lottie'
import '@/assets/selection.css';

export default {
    name: 'TalentSelection',
    components: {
        Vue3Lottie
    },
    emits: ['talents-selected'],
    setup(props, { emit }) {
        const talents = ref([]);
        const selectedTalents = ref([]);
        const isLoading = ref(true);
        const loadingAnimation = ref(loadingAnimationData);

        const fetchTalents = async () => {
            isLoading.value = true;
            try {
                talents.value = await characterService.getTalents();
            } catch (error) {
                console.error('Error fetching talents:', error);
            } finally {
                isLoading.value = false;
            }
        };

        fetchTalents();

        const toggleTalent = (talent) => {
            const index = selectedTalents.value.findIndex(t => t._id === talent._id);
            if (index > -1) {
                selectedTalents.value.splice(index, 1);
            } else if (selectedTalents.value.length < 3) {
                selectedTalents.value.push(talent);
            }
        };

        const isSelected = (talent) => {
            return selectedTalents.value.some(t => t._id === talent._id);
        };

        const confirmSelection = () => {
            if (selectedTalents.value.length > 0) {
                emit('talents-selected', selectedTalents.value);
            }
        };

        return {
            talents,
            selectedTalents,
            toggleTalent,
            isSelected,
            confirmSelection,
            isLoading,
            loadingAnimation
        };
    }
};
</script>

<style scoped>
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.talent-rarity {
    font-size: 0.8rem;
}

.talent-rarity.R,
.talent-rarity.SR,
.talent-rarity.SSR {
    padding: 2px 6px;
    border-radius: 4px;
    color: var(--color-text);
}

.talent-rarity.R {
    background-color: var(--color-talent-rarity-R);
}

.talent-rarity.SR {
    background-color: var(--color-talent-rarity-SR);
    font-weight: var(--font-weight-semibold);
}

.talent-rarity.SSR {
    background-color: var(--color-talent-rarity-SSR);
    font-weight: var(--font-weight-semibold);
}

::-webkit-scrollbar {
    display: none !important;
}
</style>