<!-- src/components/life/AttributeAllocation.vue -->
<template>
    <div class="options-selection-parent">
        <p class="options-header">Allocate Your Attributes</p>
        <div class="attributes-container">
            <div v-for="(value, attr) in attributes" :key="attr" class="attribute-item">
                <span class="attribute-name">{{ attr }}</span>
                <div class="attribute-control">
                    <button @click="decrementAttribute(attr)" :disabled="value === 1"
                        class="control-button pixel-button">-</button>
                    <span class="attribute-value">{{ value }}</span>
                    <button @click="incrementAttribute(attr)" :disabled="remainingPoints === 0"
                        class="control-button pixel-button">+</button>
                </div>
            </div>
        </div>
        <div class="remaining-points">
            Remaining Points: <span class="point-value">{{ remainingPoints }}</span>
        </div>
        <div class="button-container">
            <button @click="randomizeAttributes" class="randomize-button pixel-button">Randomize</button>
            <button @click="confirmAllocation" :disabled="remainingPoints !== 0"
                class="confirm-button pixel-button">Confirm</button>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import '@/assets/selection.css';

export default {
    name: 'PropertiesSelection',
    emits: ['properties-confirmed'],
    setup(props, { emit }) {
        const totalPoints = 30;
        const attributes = ref({
            Strength: 1,
            Intelligence: 1,
            Charisma: 1,
            Luck: 1,
            Wealth: 1
        });

        const remainingPoints = computed(() => {
            return totalPoints - Object.values(attributes.value).reduce((sum, val) => sum + val, 0);
        });

        const incrementAttribute = (attr) => {
            if (remainingPoints.value > 0) {
                attributes.value[attr]++;
            }
        };

        const decrementAttribute = (attr) => {
            if (attributes.value[attr] > 1) {
                attributes.value[attr]--;
            }
        };

        const randomizeAttributes = () => {
            let points = totalPoints - 5; // 5 for the minimum 1 point in each attribute
            Object.keys(attributes.value).forEach(attr => attributes.value[attr] = 1);

            while (points > 0) {
                const attr = Object.keys(attributes.value)[Math.floor(Math.random() * 5)];
                attributes.value[attr]++;
                points--;
            }
        };

        const confirmAllocation = () => {
            emit('properties-confirmed', attributes.value);
        };

        return {
            attributes,
            remainingPoints,
            incrementAttribute,
            decrementAttribute,
            randomizeAttributes,
            confirmAllocation
        };
    }
};
</script>

<style scoped>
.attributes-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.attribute-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-bg-light);
    border-radius: 8px;
    padding: 0.8rem 1rem;
    transition: all 0.3s ease;
}

.attribute-name {
    font-size: 1.1rem;
    color: var(--color-text);
}

.attribute-control {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.control-button {
    font-size: 1rem;
    width: 30px;
    height: 30px;
    display: flex;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: center;
}

.control-button:first-child:disabled {
    background-color: var(--color-button-disabled);
}

.control-button:first-child {
    background-color: var(--color-button-decrease);
}

.control-button:first-child:hover:not(:disabled) {
    background-color: var(--color-button-decrease);
}

.control-button:hover:not(:disabled) {
    filter: brightness(1.1);
}

.control-button:active:not(:disabled) {
    transform: translateY(1px);
}

.control-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.attribute-value {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--color-primary);
    width: 20px;
    text-align: center;
}

.remaining-points {
    font-size: 1.1rem;
    color: var(--color-text);
    margin-bottom: 2rem;
    text-align: center;
}

.point-value {
    font-weight: bold;
    color: var(--color-primary);
}

.button-container {
    display: flex;
    align-self: center;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 12px;
    justify-content: center;
}

.randomize-button,
.confirm-button {
    width: 45%;
    font-size: 1rem;
    padding: 0.8rem;
    cursor: pointer;
    font-weight: var(--font-weight-semibold);
}

.randomize-button {
    background-color: var(--color-button-option);
}

.randomize-button:hover {
    background-color: var(--color-button-option-hover);
}

.confirm-button {
    background-color: var(--color-primary);
}

.confirm-button:hover:not(:disabled) {
    background-color: var(--color-primary-hover);
}

.confirm-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .properties-selection {
        padding: 10px;
    }

    .selection-header {
        font-size: 1.3rem;
    }

    .attribute-item {
        padding: 0.6rem 0.8rem;
    }

    .control-button {
        width: 25px;
        height: 25px;
        font-size: 0.9rem;
    }

    .attribute-value {
        font-size: 0.9rem;
    }

    .remaining-points {
        font-size: 1rem;
    }

    .randomize-button,
    .confirm-button {
        font-size: 0.9rem;
        padding: 0.6rem;
    }
}
</style>