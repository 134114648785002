<template>
    <div class="stats-board">
        <div class="counter-label">Total Plays</div>
        <div class="counter">{{ formattedCount }}</div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { statsService } from '../services/statsService';

export default {
    name: 'StatsBoard',
    setup() {
        const count = ref(0);
        const displayCount = ref(0);
        const animationDuration = 2000;
        let animationStartTime;
        let animationFrame;
        let updateInterval;

        const animate = (timestamp) => {
            if (!animationStartTime) animationStartTime = timestamp;
            const progress = timestamp - animationStartTime;

            if (progress < animationDuration) {
                displayCount.value = Math.floor((progress / animationDuration) * count.value);
                animationFrame = requestAnimationFrame(animate);
            } else {
                displayCount.value = count.value;
            }
        };

        const updateCount = async () => {
            try {
                const result = await statsService.getStatus();
                const playCount = result.totalPlayCount;
                // const userCount = result.totalUserCount;
                if (playCount !== count.value) {
                    count.value = playCount;
                    animationStartTime = null;
                    requestAnimationFrame(animate);
                }
            } catch (error) {
                console.error('Failed to fetch total plays:', error);
            }
        };

        onMounted(() => {
            updateCount();
            updateInterval = setInterval(updateCount, 30000); // 每30秒更新一次
        });

        onUnmounted(() => {
            if (animationFrame) {
                cancelAnimationFrame(animationFrame);
            }
            if (updateInterval) {
                clearInterval(updateInterval);
            }
        });

        const formatCount = (value) => {
            if (value >= 1e9) {
                return (value / 1e9).toFixed(1) + 'b';
            } else if (value >= 1e4) {
                return (value / 1e4).toFixed(1) + 'w';
            } else {
                return new Intl.NumberFormat().format(value);
            }
        };

        const formattedCount = computed(() => {
            return formatCount(displayCount.value);
        });

        return {
            formattedCount
        };
    }
}
</script>

<style scoped>
.stats-board {
    position: relative;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.counter-label {
    color: white;
    font-size: 1.8rem;
}

.counter {
    margin-left: 16px;
    font-size: 2.2rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-stats);
}

@media (max-width: 768px) {
    .stats-board {
        .counter {
            font-size: 1.8rem;
        }

        .counter-label {
            font-size: 1.5rem;
        }
    }
}

@media (max-width: 480px) {
    .stats-board {
        .counter {
            font-size: 1.8rem;
        }

        .counter-label {
            font-size: 1.5rem;
        }
    }
}
</style>