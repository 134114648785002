<template>
    <div class="ranking-tabs">
        <div class="tab-buttons">
            <button @click="activeTab = 'users'" :class="{ active: activeTab === 'users' }" class="tab-button">
                Users
            </button>
            <button @click="activeTab = 'countries'" :class="{ active: activeTab === 'countries' }" class="tab-button">
                Countries
            </button>
        </div>
        <div class="tab-content">
            <UserRanking v-if="activeTab === 'users'" />
            <CountryRanking v-if="activeTab === 'countries'" />
        </div>
    </div>
</template>

<script>
import UserRanking from './UserRanking.vue'
import CountryRanking from './CountryRanking.vue'

export default {
    name: 'RankingTabs',
    components: {
        UserRanking,
        CountryRanking
    },
    data() {
        return {
            activeTab: 'users'
        }
    }
}
</script>

<style scoped>
.ranking-tabs {
    background-color: var(--color-bg);
    border-radius: 10px 10px 0 0;
    padding: 0px;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.tab-buttons {
    display: flex;
    margin-bottom: 10px;
}

.tab-button {
    flex: 1;
    padding: 8px 8px;
    background-color: var(--color-primary);
    border: 2px solid transparent;
    color: var(--color-text);
    cursor: pointer;
    font-size: 14px;
    margin: 4px;
    border-radius: 5px;
    transition: all 0.3s;
    position: relative;
    box-shadow: 0 4px var(--shadow-color);
}

.tab-button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tab-button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tab-button.active {
    background-color: var(--color-bg);
    border-color: var(--color-primary);
    color: var(--color-primary);
    box-shadow: 0 2px var(--shadow-color);
    transform: translateY(2px);
}

.tab-button:hover:not(.active) {
    background-color: var(--color-primary-hover);
}

.tab-button:active {
    box-shadow: 0 2px var(--shadow-color);
    transform: translateY(2px);
}

.tab-content {
    width: 100%;
}
</style>