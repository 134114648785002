import { ENDPOINTS } from './endpoints';
import { api } from '../foundation/api';
import { rankingService } from './rankingService';
import { ref } from 'vue';
import { useEventBus } from '../composables/useEventBus';

class GameService {
    constructor() {
        if (!GameService.instance) {
            this.countryStats = ref(null);
            this.rankingInterval = null;
            this.previousTop3Countries = null;
            this.previousTop3Users = null;
            GameService.instance = this;
        }
        return GameService.instance;
    }

    async fetchGameData() {
        try {
            const response = await api.get(`${ENDPOINTS.COUNTDOWN.ACTIVITY}`);
            await this.fetchCountryRankings();
            return response;
        } catch (error) {
            console.error('Failed to get activity countdown:', error);
            throw error;
        }
    }

    async fetchSeasonRewards(userId) {
        try {
            const response = await api.get(`${ENDPOINTS.REWARDS.FETCH}/${userId}`);
            console.log('season rewards:', response);
            return response;
        } catch (error) {
            console.error('Failed to get season rewards:', error);
            throw error;
        }
    }

    async checkSeasonStatus() {
        try {
            const response = await api.get(`${ENDPOINTS.REWARDS.CHECK}`);
            return response;
        } catch (error) {
            console.error('Failed to check season rewards:', error);
            throw error;
        }
    }

    startRankingUpdates(interval = 30000) {
        this.stopRankingUpdates();
        this.fetchCountryRankings();
        this.fetchUserRankings();
        this.rankingInterval = setInterval(() => {
            this.fetchCountryRankings();
            this.fetchUserRankings();
        }, interval);
    }

    stopRankingUpdates() {
        if (this.rankingInterval) {
            clearInterval(this.rankingInterval);
            this.rankingInterval = null;
        }
    }

    async fetchCountryRankings() {
        try {
            const result = await rankingService.getWorldRanking();
            // for (const country of result) {
            //     country.weightedScore += (Math.random() - 0.5) * 20;
            // }
            this.countryStats.value = result;
            this.checkTop3CountryChanges();
        } catch (error) {
            console.error('Error fetching country rankings:', error);
        }
    }

    checkTop3CountryChanges() {
        const currentTop3 = this.countryStats.value.slice(0, 3);
        if (this.previousTop3Countries) {
            const changes = this.getTop3CountryChanges(this.previousTop3Countries, currentTop3);
            if (changes.length > 0) {
                this.notifyCountryTop3Changes(changes);
            }
        }
        this.previousTop3Countries = currentTop3;
    }

    getTop3CountryChanges(previous, current) {
        return current.map((country, index) => {
            if (!previous[index] || country.country !== previous[index].country) {
                return {
                    country: country.country,
                    newRank: index + 1,
                    oldRank: previous.findIndex(c => c.country === country.country) + 1
                };
            }
            return null;
        }).filter(change => change !== null);
    }

    getTop3UserChanges(previous, current) {
        return current.map((user, index) => {
            if (!previous[index] || user.name !== previous[index].name) {
                return {
                    name: user.name,
                    newRank: index + 1,
                    oldRank: previous.findIndex(u => u.name === user.name) + 1
                };
            }
            return null;
        }).filter(change => change !== null);
    }

    notifyCountryTop3Changes(changes) {
        const eventBus = useEventBus();
        eventBus.emit('countryTop3Changes', changes);
    }

    async fetchUserRankings() {
        try {
            const result = await rankingService.getAllUsersRanking();
            this.checkUserTop3Changes(result.rankings);
        } catch (error) {
            console.error('Error fetching personal rankings:', error);
        }
    }

    checkUserTop3Changes(currentRankings) {
        const currentTop3 = currentRankings.slice(0, 3);
        if (this.previousTop3Users) {
            const changes = this.getTop3UserChanges(this.previousTop3Users, currentTop3);
            if (changes.length > 0) {
                this.notifyUserTop3Changes(changes);
            }
        }
        this.previousTop3Users = currentTop3;
    }

    notifyUserTop3Changes(changes) {
        const eventBus = useEventBus();
        eventBus.emit('userTop3Changes', changes);
    }

    async fetchCountryStats() {
        return await this.fetchCountryRankings();
    }

    getCountryStats(countryName) {
        if (this.countryStats.value == null || this.countryStats.value.length === 0) {
            return {
                country: countryName,
                totalPlayCount: 0,
                userCount: 0,
                weightedScore: 0
            };
        }
        const country = this.countryStats.value.find(country => country.country === countryName);
        if (country == null) {
            return {
                country: countryName,
                totalPlayCount: 0,
                userCount: 0,
                weightedScore: 0
            };
        }
        return country;
    }
}

// Ensure a single instance
const instance = new GameService();
// Object.freeze(instance);

export default instance;