import { reactive, computed } from 'vue';

let globalSoundState = null;

function createSoundState() {
    return reactive({
        isMuted: false,
        toggleMute() {
            this.isMuted = !this.isMuted;
            console.log('isMuted', this.isMuted);
        }
    });
}

function getGlobalSoundState() {
    if (!globalSoundState) {
        globalSoundState = createSoundState();
    }
    return globalSoundState;
}

export function useSound() {
    const soundState = getGlobalSoundState();

    return {
        isMuted: computed(() => soundState.isMuted),
        toggleMute: () => soundState.toggleMute()
    };
}