import Auth0Lock from 'auth0-lock';

const lock = new Auth0Lock(
  process.env.VUE_APP_AUTH0_CLIENT_ID,
  process.env.VUE_APP_AUTH0_DOMAIN,
  {
    auth: {
      redirectUrl: window.location.origin,
      responseType: 'token id_token',
      params: {
        scope: 'openid profile email'
      }
    },
    autoclose: true,
    closable: true,
    theme: {
      logo: '/logo.png',
      primaryColor: '#58c55d'
    },
    languageDictionary: {
      title: "Sign in"
    },
    allowSignUp: true
  }
);

export default lock;
