// src/composables/useNotificationQueue.js
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { useEventBus } from './useEventBus';
import { inject } from 'vue';
import cheerSound from '@/assets/cheering.mp3';  // 请确保路径正确

const isMuted = inject('isMuted');

export function useNotificationQueue() {
    const eventBus = useEventBus();
    const notificationQueue = ref([]);
    const currentNotification = ref({ type: null, changes: [] });
    const isShowingNotification = ref(false);

    let audio = null;
    let isAudioInitialized = false;

    const initializeAudio = () => {
        if (!isAudioInitialized) {
            audio = new Audio(cheerSound);
            isAudioInitialized = true;
        }
    };

    const playNotificationSound = () => {
        if (isAudioInitialized && !isMuted.value) {
            audio.play().catch(error => console.error('Error playing audio:', error));
        }
    };

    const addToQueue = (type, changes) => {
        changes.forEach(change => {
            notificationQueue.value.push({ type, changes: [change] });
        });
        if (!isShowingNotification.value) {
            showNextNotification();
        }
    };

    const showNextNotification = () => {
        if (notificationQueue.value.length > 0 && !isShowingNotification.value) {
            isShowingNotification.value = true;
            currentNotification.value = notificationQueue.value.shift();
            playNotificationSound();
            setTimeout(() => {
                currentNotification.value = null;
                isShowingNotification.value = false;
                nextTick(() => {
                    showNextNotification();
                });
            }, 5000);
        }
    };

    const listenRankChanges = () => {
        eventBus.on('userTop3Changes', (changes) => {
            addToQueue('user', changes);
        });
        eventBus.on('countryTop3Changes', (changes) => {
            addToQueue('country', changes);
        });
    };

    onMounted(() => {
        listenRankChanges();
        document.addEventListener('click', initializeAudio, { once: true });
    });

    onUnmounted(() => {
        eventBus.off('userTop3Changes');
        eventBus.off('countryTop3Changes');
    });

    return {
        currentNotification,
    };
}