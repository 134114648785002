<template>
    <div class="life-modal">
        <div class="modal-content">
            <div class="modal-header">
                <div class="close-button-container">
                    <CloseButton @click="$emit('close')" />
                </div>
                <div v-if="step !== 'country-selection'" class="current-country">
                    <button @click="openCountrySelection" class="change-country-button">
                        <span class="country-flag">{{ getCountryFlag(lastSelectedCountry) }}</span>
                        <span class="country-name">{{ lastSelectedCountry }}</span>
                        <span class="change-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div class="life-modal-content">
                <country-selection v-if="step === 'country-selection'" :initialCountry="lastSelectedCountry"
                    @country-selected="handleCountrySelected" />
                <role-selection v-else-if="step === 'role-selection'" @role-selected="handleRoleSelected" />
                <talent-selection v-else-if="step === 'talent-selection'" @talents-selected="handleTalentsSelected" />
                <properties-selection v-else-if="step === 'properties-selection'"
                    @properties-confirmed="handlePropertiesSelected" />
                <life-story v-else-if="step === 'life-story'" :life="life" @generate-new-life="generateNewLife"
                    @regenerate-new-life="regenerateNewLife" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import RoleSelection from './RoleSelection.vue';
import TalentSelection from './TalentSelection.vue';
import CountrySelection from './CountrySelection.vue';
import PropertiesSelection from './PropertiesSelection.vue';
import { useUserStore } from '../../stores/userStore';
import LifeStory from './LifeStory.vue';
import { restartLifeService } from '../../services/restartLifeService';
import CloseButton from '../base/CloseButton.vue';
import { useCountries } from '@/composables/useCountries';

export default {
    name: 'LifeModal',
    components: {
        CountrySelection,
        RoleSelection,
        TalentSelection,
        PropertiesSelection,
        LifeStory,
        CloseButton
    },
    emits: ['close'],
    setup(props, { emit }) {
        const showToast = inject('showToast');
        const userStore = useUserStore();
        const step = ref(userStore.getSelectedCountry() ? 'role-selection' : 'country-selection');
        const life = ref(null);
        const curSelection = ref(null);

        const lastSelectedCountry = computed(() => {
            return userStore.getSelectedCountry() || 'United States';
        });

        const handleCountrySelected = (country) => {
            userStore.setSelectedCountry(country);
            step.value = 'role-selection';
        };

        const openCountrySelection = () => {
            step.value = 'country-selection';
        };

        const handleRoleSelected = (role) => {
            curSelection.value = { ...curSelection.value, roleName: role };
            step.value = 'talent-selection';
        };

        const handleTalentsSelected = (talents) => {
            curSelection.value = { ...curSelection.value, talents };
            step.value = 'properties-selection';
        };

        const handlePropertiesSelected = async (attributes) => {
            curSelection.value = { ...curSelection.value, attributes };
            step.value = 'life-story';
        };

        const restartLife = async (talents, role, attributes) => {
            try {
                return await restartLifeService.restartLife(
                    userStore.getUserId(),
                    attributes,
                    talents,
                    role,
                    userStore.getUserName(),
                    userStore.getSelectedCountry(),
                );
            } catch (error) {
                console.error('Error restarting a new life:', error);

                showToast('Error restarting a new life.', 'error');
                emit('close');
                return null;
            }
        };

        const generateNewLife = async () => {
            const result = await restartLife(
                curSelection.value.talents,
                curSelection.value.roleName,
                curSelection.value.attributes
            );
            life.value = result;
            return life.value;
        };

        const regenerateNewLife = () => {
            life.value = null;
            step.value = 'role-selection';
        };

        const { countryFlagEmojis } = useCountries();

        const getCountryFlag = (countryName) => {
            return countryFlagEmojis[countryName] || '🏳️';
        };

        return {
            step,
            life,
            lastSelectedCountry,
            handleRoleSelected,
            handleTalentsSelected,
            handleCountrySelected,
            generateNewLife,
            regenerateNewLife,
            openCountrySelection,
            getCountryFlag,
            handlePropertiesSelected
        };
    }
};
</script>

<style scoped>
.life-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    border-radius: 10px;
    background-color: var(--color-bg);
    width: 80vw;
    max-width: 800px;
    min-height: 300px;
    max-height: 80vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    align-items: center;
    justify-content: center;
}

.life-modal-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 500px;
    overflow-x: hidden;
}

.life-modal-content::-webkit-scrollbar {
    display: none;
}

.close-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
}

.change-country-button-container {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.current-country {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.change-country-button {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    color: var(--color-text);
    font-size: 0.9em;
    cursor: pointer;
    padding: 6px 12px;
    transition: all 0.3s ease;
}

.change-country-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.country-flag {
    font-size: 1.2em;
    margin-right: 8px;
}

.country-name {
    white-space: nowrap;
    margin-right: 8px;
}

.change-icon {
    display: flex;
    align-items: center;
}

.change-icon svg {
    width: 14px;
    height: 14px;
}
</style>