<template>
    <div class="ranking-container" v-if="countryStats && countryStats.length > 0">
        <h2>Top 5 Country Rankings</h2>
        <ul>
            <li v-for="(country, index) in countryStats" :key="index" class="ranking-item">
                <span class="country-rank">{{ index + 1 }}</span>
                <span class="country-flag">{{ getCountryFlag(country.country) }}</span>
                <div class="score-bar-container">
                    <div class="score-bar" :style="getScoreBarStyle(country.weightedScore)"></div>
                </div>
                <span class="country-score">{{ country.weightedScore }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useCountries } from '../../composables/useCountries'
import gameService from '@/services/gameService'

export default {
    name: 'RankingWidget',
    setup() {
        const { countryFlagEmojis } = useCountries()
        const maxScore = ref(100);
        const countryStats = computed(() => gameService.countryStats.value);

        const getCountryFlag = (countryName) => {
            return countryFlagEmojis[countryName] || '🏳️'
        }

        const getScoreBarStyle = (score) => {
            let backgroundColor;
            if (score > 80) {
                backgroundColor = '#f44336'; // 高分颜色
            } else if (score > 50) {
                backgroundColor = '#ff9800'; // 中等分颜色
            } else {
                backgroundColor = '#4caf50'; // 低分颜色
            }
            const widthPercentage = (score / maxScore.value) * 100;
            return {
                width: `${widthPercentage}%`,
                backgroundColor,
            };
        };
        return {
            getScoreBarStyle,
            getCountryFlag,
            countryStats
        };
    },
};
</script>

<style scoped>
.ranking-container {
    visibility: hidden;
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 10px;
    border-radius: 10px;
    z-index: 200;
    width: 300px;
}

@media (max-width: 768px) {
    .ranking-container {
        visibility: hidden;
    }
}

.ranking-container h2 {
    margin: 0;
    font-size: 1.2em;
    text-align: center;
}

.ranking-container ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
}

.ranking-item {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 2px;
}

.country-rank {
    width: 20px;
    font-size: 1.2em;
    font-weight: var(--font-weight-medium);
    text-align: center;
}

.country-flag {
    font-size: 1.5em;
    display: flex;
    align-items: left;
    margin: 4px;
}

.score-bar-container {
    flex: 2;
    height: 16px;
    margin: 0 2px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.score-bar {
    height: 100%;
    align-self: flex-start;
}

.country-score {
    flex: 1;
    font-size: 1em;
    text-align: right;
}
</style>