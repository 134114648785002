<template>
    <div class="options-selection-parent">
        <p class="options-header">Choose Your Role</p>
        <div v-if="isLoading" class="loading-container">
            <Vue3Lottie :animationData="loadingAnimation" :height="200" :width="200" />
        </div>
        <div v-else class="options-selection-container">
            <TransitionGroup name="option-list" tag="div" class="options-grid">
                <button class="option-button" v-for="(role, index) in roles" :key="role._id" @click="selectRole(role)"
                    :class="{ selected: isSelected(role) }" :style="{ animationDelay: `${index * 50}ms` }">
                    {{ role.name }}
                </button>
            </TransitionGroup>
        </div>
        <div class="options-confirm-button-container">
            <button @click="confirmSelection" :disabled="!selectedRole" class="options-confirm-button pixel-button">
                Next
            </button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { characterService } from '@/services/characterService';
import loadingAnimationData from '@/assets/anim/loading-hand.json';
import { Vue3Lottie } from 'vue3-lottie'
import '@/assets/selection.css';

export default {
    name: 'RoleSelection',
    components: {
        Vue3Lottie
    },
    emits: ['role-selected'],
    setup(props, { emit }) {
        const roles = ref([]);
        const selectedRole = ref(null);
        const isLoading = ref(true);
        const loadingAnimation = ref(loadingAnimationData);

        const fetchRoles = async () => {
            isLoading.value = true;
            try {
                roles.value = await characterService.getRoles();
            } catch (error) {
                console.error('Error fetching roles:', error);
            } finally {
                isLoading.value = false;
            }
        };

        fetchRoles();

        const selectRole = (role) => {
            selectedRole.value = role.name === selectedRole.value ? null : role.name;
        };

        const isSelected = (role) => {
            return selectedRole.value === role.name;
        };

        const confirmSelection = () => {
            if (selectedRole.value) {
                emit('role-selected', selectedRole.value);
            }
        };

        return {
            roles,
            selectedRole,
            selectRole,
            isSelected,
            confirmSelection,
            isLoading,
            loadingAnimation
        };
    }
};
</script>

<style scoped>
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

::-webkit-scrollbar {
    display: none !important;
}
</style>